<template>
    <body style="background-color:  #F8F9FA;height: 100vh;">

        <div class="">
            <div class="row ">
                <Sidebar_web></Sidebar_web>
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 bg-white" style="min-height: 100vh;">
                    <Z_bar_module></Z_bar_module>
                    <div class="row justify-content-start ">
                        <div class="h3">Help</div>
                       <div class="h5">1. How to create Line Notify Token</div>
                       <a class="ms-4" href="https://eccs.sut.ac.th/ccs/font/token_key.pdf" target="_blank">Document Generate Line Token Link</a>
                       <a class="ms-4" href="https://notify-bot.line.me/en/" target="_blank">Generate Line Token Here!</a>

                    </div>
                </div>
            </div>

        </div>
    </body>

</template>

<script>
    import Sidebar_web from '@/components/sidebar_web.vue'
    import Z_bar_module from '@/components/z_bar_module.vue'
    export default {
        name: 'App',
        components: {
            Sidebar_web,
            Z_bar_module
        },
        data() {
            return {
            }
        },
        methods: {
         
        },
        created() {
        }


    }
</script>

<style>
</style>
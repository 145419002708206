<template>
    <Navbar></Navbar>

    <body>
        <div class="col-12" style="height: 100vh; background-color: #F8F9FA;">
            <div class="row justify-content-center">
                <div style='height: 80px;'></div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-11 col-11">
                    <div class="card px-5 py-4  border-0 rounded-4">
                        <div style='height: 10px;'></div>
                        <form>
                            <div v-if="s_edit=='edit'" class="h1 text-center">Edit Setting </div>
                            <div v-else class="h1 text-center">+ Add Setting </div>

                            <div class="col-12 mt-1">
                                <div class="row">
                                    <span class="col-12" style="font-weight: bold;">Type: <span>
                                            {{s_mode}}</span></span>
                                </div>
                            </div>
                            <div class="col-12 mt-1">
                                <div class="row">
                                    <span class="">Name Setting</span>

                                    <input v-model="itemOne.name" type="text" key="Value"
                                        class="col-12 rounded-4  border-1 " style="height: 40px;  ">
                                </div>
                            </div>


                            <div class="col-12 mt-1" v-if="s_mode=='Door'">
                                <span class=" text-start">Condition</span>
                                <div class="row">
                                    <select v-model="itemOne.condition" class=" form-select rounded-4 "
                                        aria-label="Default select example" style="width: 70px;">
                                        <option value="max_alarm"> &gt; </option>
                                    </select>
                                    <input v-model="itemOne.value" type="number" key="Value"
                                        class="col-6 rounded-4  border-1 ms-2" style="height: 40px;  ">
                                    <div class="col-3 pt-2 text-end">
                                        Minute
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-1" v-else-if="s_mode=='Temperature'">
                                <div class="row">
                                    <span class="text-start">Condition</span>
                                    <select v-model="itemOne.condition" class="form-select rounded-4 "
                                        aria-label="Default select example" style="width: 70px;">
                                        <option selected>Condition</option>
                                        <option value="min_alarm">&lt;</option>
                                        <option value="max_alarm"> &gt; </option>
                                    </select>
                                    <input v-model="itemOne.value" type="number" key="Value"
                                        class="col-3 rounded-4  border-1 ms-2" style="height: 40px;  width: 140px">
                                    <div class="col-2 pt-2 text-center">
                                        °C
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-1">
                                <div class="row">
                                    <span class=" text-start">To Do</span>
                                    <select v-model="itemOne.todo" class="col-3 form-select rounded-4 "
                                        aria-label="Default select example" style=" ">
                                        <option selected></option>
                                        <option value="Notification">Notification</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-12 mt-1">
                                <div class="row">
                                    <span class=" text-start">Message <div style="font-size: 10px;">{{ nameDevice }}
                                        </div></span>
                                    <input v-model="itemOne.message" type="text" key="Value"
                                        class="rounded-4  border-1 " style="height: 40px;">
                                </div>
                            </div>

                            <!-- Submit button -->
                            <div class="text-center mt-5">
                                <button v-if="s_edit =='edit'" type="button" data-mdb-button-init data-mdb-ripple-init
                                    class="btn btn-primary btn-block mb-4 col-12 rounded-4"
                                    @click="editSetting(s_mode)">
                                    <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Edit
                                    Setting</button>
                                <button v-else type="button" data-mdb-button-init data-mdb-ripple-init
                                    class="btn btn-primary btn-block mb-4 col-12 rounded-4"
                                    @click="AddNewSetting(s_mode)">+ Add
                                    Setting</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </body>

</template>

<script>
    import axios from 'axios'
    import Navbar from "@/components/navbar_web.vue";
    export default {
        name: 'App',
        components: {
            Navbar
        },
        data() {
            return {
                edMeagess: null,
                s_id: null,
                d_id: null,
                s_edit: null,
                s_mode: null,
                nameDevice: null,
                itemOne: {
                    message: '',
                    name: '',
                    condition: '',
                    value: '',
                    todo: ''
                },
                items: []
            }
        },
        methods: {
            // extractedString() {
            //     // Split the string by colon and trim any whitespace
            //     // this.itemOne.message = this.itemOne.message.split(':')[1].trim();

            // },
            extractString(str) {
                var result = str.split(": ")[1];
                console.log("load...", str)
                return result;

            },
            async AddNewSetting(type) {
                try {
                    var path = ''
                    if (type == "Temperature") {
                        path = 'temp'
                    } else {
                        path = 'door'
                    }
                    var load = await axios.post('https://fzm-api.sgroup.io/api/setting/' + path, {
                        device_id: this.d_id,
                        name: this.itemOne.name,
                        condition: this.itemOne.condition,
                        value: this.itemOne.value,
                        todo: this.itemOne.todo,
                        message: this.nameDevice + ": " + this.itemOne.message
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    console.log(load.data);
                    await this.$router.go(-1);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.AddNewSetting(type);
                    } else {
                        console.error(error);
                    }
                }
            },
            async editSetting(type) {
                try {
                    var path = ''
                    if (type == "Temperature") {
                        path = 'temp'
                    } else {
                        path = 'door'
                    }
                    var load = await axios.put('https://fzm-api.sgroup.io/api/setting/' + path, {
                        id: this.s_id,
                        device_id: this.d_id,
                        name: this.itemOne.name,
                        condition: this.itemOne.condition,
                        value: this.itemOne.value,
                        todo: this.itemOne.todo,
                        message: this.nameDevice + ": " + this.itemOne.message
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    console.log(load.data);
                    await this.$router.go(-1);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.editSetting(type);
                    } else {
                        console.error(error);
                    }
                }
            },

            async getDataDevice(id, mode, s_id) {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/device', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'deviceid': id
                        }
                    })
                    if (mode == 'Temperature') {
                        var p = load.data.TempSetting;
                        this.itemOne = p.find(setting => setting.id == s_id);
                    } else {
                        this.itemOne = load.data.DoorSetting[0]
                    }
                    if (this.s_edit == 'edit') {
                        this.itemOne.message = this.extractString(this.itemOne.message);
                    }
                    console.log(load.data);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getDataDevice(id, mode, s_id);
                    } else {
                        console.error(error);
                    }
                }

            },
        },
        created() {
            this.d_id = this.$route.query.did || '';
            this.s_id = this.$route.query.sid || '';
            this.s_edit = this.$route.query.edit || '';
            this.s_mode = this.$route.query.mode || '';
            this.nameDevice = this.$route.query.name || '';
            if (this.s_edit == 'edit') {
                console.log('a')
                this.getDataDevice(this.d_id, this.s_mode, this.s_id);
                this.edMeagess = this.extractString(this.itemOne.message);
            } else {
                this.itemOne = []
            }
        }
    }
</script>

<style>
</style>
<template>
 <body>
  <div>MQTT Test</div>
 </body>
</template>
  
  <script>

    import {
      useMQTT
    } from 'mqtt-vue-hook'
    const mqttHook = useMQTT()
    var protocol = 'wss'
    var host = 'fzm-ws.sgroup.io'
    var port = 443
  
    export default {
      data() {
       return{

       }
      },
    //   watch: {
    //     id(newId) {
    //       // Update the src link when id changes
    //       this.updateIframeSrc(newId);
    //     },
    //   },
      computed: {
        // iframeSrc() {
        //   // Compute the src link based on the current id value
        //   return `http://localhost:2000/${this.id}`;
        // },
      },
      methods: {  
         CallMQTTONE() {
        mqttHook.connect(`${protocol}://${host}:${port}/ws`, {
            username: 'frontend',
            password: '8w95BXTq1gZH',
            // clean: false,
            keepalive: 60,
            // clientId: `mqtt_client_${Math.random().toString(16).substring(2, 10)}`,
            connectTimeout: 4000,
          }),
          mqttHook.subscribe(
            ['@msg/FZMIOT001/SN123456789asd'],
            1, {
              properties: {
                // userProperties: {...}
              },
            },
            () => {

              console.log('subscribed!')
              // this.getHightData();
            }
          )

        mqttHook.registerEvent(
          '@msg/FZMIOT001/SN123456789asd',
          (_topic, message) => {

            // console.log(message)
            const messageString = message.toString('utf8');
            console.log(JSON.parse(messageString));
            // callback
          },
          // 'string_key',
          'mqtt-client-2', // clientID
        )
      },
  
    
        
    
      },
      mounted() {
        this.CallMQTTONE();
        // this.getLastRainData();
        // this.getHightData();
      },
      created() {
        
      },
      beforeMount() {
      },
      unmounted() {
        mqttHook.unSubscribe("@msg/FZMIOT001/SN123456789asd")
        mqttHook.unRegisterEvent("@msg/FZMIOT001/SN123456789asd")

        // mqttHook.unSubscribe("")
        // mqttHook.unRegisterEvent("")
      }
      //   createConnection() {
  
      //   // Connect to MQTT broker
      //   mqttHook.connect(`${protocol}://${host}:${port}/ws`,{
      //     username:'qqq',
      // password:'qqq',
      //   // clean: false,
      //   keepalive: 60,
      //   // clientId: `mqtt_client_${Math.random().toString(16).substring(2, 10)}`,
      //   connectTimeout: 4000,
      //   }).then(() => {
      //     console.log('Connected to MQTT broker');
  
      //     // Subscribe to a topic
      //     mqttHook.subscribe('@msg', { qos: 0 }, (topic, payload) => {
      //       console.log(`Received message on topic ${topic}: ${payload}`);
      //     });
      //   });
      //   }
      //   beforeDestroy() {
      //   // Disconnect from MQTT broker when the component is destroyed
      //   mqttHook.disconnect();
      // },
    };
  </script>
  <script setup>

  </script>
  <style>
   
  </style>
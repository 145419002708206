<template>
<router-view />
</template>

<script>
    import axios from 'axios'
export default {
  name: 'App',
  components: {
  },methods:{
    newAccToken() {
    var load =  axios.post('https://fzm-api.sgroup.io/auth/refresh', {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken:  localStorage.getItem('refreshToken')
    }, {
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('refreshToken')
        }
    })
    console.log(load.data);
    localStorage.setItem('accessToken', load.data.accessToken)
    localStorage.setItem('refreshToken', load.data.refreshToken)
  },
  }
}
</script>

<style>

@font-face {
    font-family: ARIAL;
    src: url(../src/assets/front/ARIAL.TTF);
}

div{
  font-family:  ARIAL;
  margin: 0;
  padding: 0px;
  border: 0;
  padding-top:0px;
}
h1{
  font-family:  ARIAL;
}

body{
  margin: 0;
  padding: 0;
  background-position: 100%;
  /* background-color: red; */
}
#app {
  margin: 0px;
  padding: 0px;
  font-family:  Maehongson;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>

<template>

    <body style="background-color:  #F8F9FA;height: 100vh;">
        <div class="">
            <div class="row ">
                <Sidebar_web></Sidebar_web>
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 bg-white" style="min-height: 100vh;">
                    <Z_bar_module></Z_bar_module>
                    <div class="row justify-content-center ">
                        <div class="col-12 px-3">
                            <div style="height: 5px;"></div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="h4"> </div>
                                </div>
                            </div>
                            <div style="font-weight: bold;font-size: 20px;">Filter</div>
                            <div class="col-6">
                                <form action="">
                                    <label for="country" style="font-size: 16px;">Country: </label>
                                    <select name="country" id="country" style="width: 200px;height: 32px;"
                                        class="rounded-4 ms-2">
                                        <option value="volvo">All Country</option>
                                    </select>
                                </form>
                            </div>

                            <div v-if="role!='User'" class=" mt-3 mb-2" style="font-weight: bold;font-size: 20px;">
                                <font-awesome-icon :icon="['fas', 'map-location-dot']" /> List Location
                                <button v-if="editLocation == true" type="button" class="btn btn-info rounded-5 ms-3 "
                                    @click="changeStateEdit()">
                                    <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Press to disable edit
                                    Loaction</button>
                                <button v-else type="button" class="btn btn-light rounded-5 ms-3 border"
                                    @click="changeStateEdit()">
                                    <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Press to enable edit
                                    Loaction</button>
                            </div>
                            <div class="row justify-content-start">
                                <div class="col-xl-3 col-lg-4  col-md-6 col-sm-6 col-12 mt-3"
                                    v-for="(item, index) in items" :key="index">
                                    <router-link :to="'/inside/location/' + item.id" style="text-decoration: none;">
                                        <div class="card rounded-5 gr-card border-0 " style="height: 175px;">
                                            <div class="card-body">
                                                <div class=" px-1 text-white">
                                                    <div style="font-size: 16px;">
                                                        <font-awesome-icon :icon="['fas', 'temperature-low']"
                                                            style="font-size: 29px;" />Name {{item.name}}</div>
                                                    <div style="font-size: 14px;"> {{ item.des }}</div>
                                                    <hr>
                                                    <div class="mt-3"
                                                        style="font-size: 15px; color: #FFB205;font-weight: bold;">
                                                        User Access Project : {{ item.UserProjects.length }} Users
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div style="font-size: 12px;">Country {{ item.Country}}
                                                            </div>
                                                            <div style="font-size: 10px;">Update At:
                                                                {{ item.createdAt }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <div class="row justify-content-start mt-1 " v-if="editLocation==true">
                                        <div class="col-1"></div>
                                        <button type="button" class=" col-3 ms-1 btn btn-primary rounded-4"
                                            style="font-size: 11px;">
                                            <router-link :to="'/add/?pid='+item.id+'&edit=edit'"
                                                style="text-decoration: none;">
                                                <font-awesome-icon :icon="['fas', 'pen-to-square']"
                                                    style="color: white;" /> <span style="color: white;">Edit</span>
                                            </router-link>
                                        </button>

                                        <button type="button" class=" col-3 ms-1 btn btn-danger rounded-4"
                                            style="font-size: 11px;" data-bs-toggle="modal"
                                            data-bs-target="#deleteLocationModal"
                                            @click="delete_id=item.id,delete_name=item.name">
                                            <font-awesome-icon :icon="['fas', 'trash']" /> Delete</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
                <div class="modal fade" id="deleteLocationModal" tabindex="-1"
                    aria-labelledby="deleteLocationModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="deleteLocationModalLabel">Delete Location</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">
                                Confirm Delete Location
                                <div>Name: {{ delete_name }}</div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-danger" @click="delLocationOne(delete_id)"
                                    data-bs-dismiss="modal">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </body>

</template>

<script>
    import '../App.vue'
    import axios from 'axios'
    import Sidebar_web from '@/components/sidebar_web.vue'
    import Z_bar_module from '@/components/z_bar_module.vue'
    // import Navbar from "@/components/navbar_web.vue";
    export default {
        name: 'App',
        components: {
            Sidebar_web,
            Z_bar_module
            // Navbar
        },
        data() {
            return {
                role: '',
                editLocation: false,
                delete_id: null,
                delete_name: null,
                items: [],
            }
        },
        methods: {
            changeStateEdit() {
                if (this.editLocation == false) {
                    this.editLocation = true;
                } else {
                    this.editLocation = false;
                }
            },
            async getLocationAll() {
                if (localStorage.getItem('accessToken') == null || localStorage.getItem('accessToken') == "null") {
                    this.$router.push({
                        path: '/login'
                    });
                    return; // Ensure to return after navigation
                }

                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/project/all', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    });
                    console.log("load.status");
                    console.log(load);
                    this.items = load.data;
                } catch (error) {
                    console.log(error.response.status);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getLocationAll();
                    } else {
                        console.error(error);
                    }
                }
            },
            async delLocationOne(id_location) {
                try {
                    var load = await axios.delete('https://fzm-api.sgroup.io/api/project/' + id_location, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    });
                    console.log('API response:', load.data); // Log the API response data
                    this.getLocationAll();
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.delLocationOne();
                    } else {
                        console.error(error);
                    }
                }
            }
        },
        created() {
            this.role = localStorage.getItem('role');
            this.getLocationAll();

        }


    }
</script>

<style>
    .gr-card {
        background-image: linear-gradient(90deg, #5DE0E6 0%, #004AAD 100%);
        color: white;
    }

    .btn-add {
        background-image: linear-gradient(90deg, #5DE0E6 0%, #004AAD 100%);
        color: white;
    }
</style>
<template>

    <Navbar></Navbar>

    <body style="background-color: white;height: 100vh;">

        <div style="height: 20px;"></div>
        <div class="col-12 container">
            <div class="row">
                <div class="col-1 text-start" style="min-width: 175px;"><span class="h6">
                        <font-awesome-icon :icon="['fas', 'user-plus']" /> Add Users
                        <button class="btn   border-0 rounded-circle" data-bs-toggle="modal"
                            data-bs-target="#adduserModal"
                            style="height: 35px;width: 35px;color: white; background-color:  #0097B2;">
                            <div>+</div>
                        </button>
                    </span></div>
                <div v-if="role!='User'" class="col-1 text-start" style="min-width: 175px;"><span class="h6">
                        <font-awesome-icon :icon="['fas', 'boxes-stacked']" /> Add Device
                        <router-link :to="'/adddevice/?did='+$route.params.id">
                            <button class="btn   border-0 rounded-circle" type="submit"
                                style="height: 35px;width: 35px;color: white; background-color:  #0097B2;">
                                <div>+</div>
                            </button>
                        </router-link>
                    </span></div>

                <div class=" col text-end me-3">
                    <div v-if="dataProject!=null" class="mt-2" style="">Notification Setting
                        <font-awesome-icon :icon="['fas', 'bell']" style="font-size: 20px;" /> <button type="button"
                            class="btn btn-dark rounded-5 text-white" style="font-size: 12px; width: 73px;"
                            data-bs-toggle="modal" data-bs-target="#changeNotiEmailModal">Change</button></div>
                </div>

            </div>

            <div class="h5 mt-2">
                <font-awesome-icon :icon="['fas', 'list']" /> Freezer List&nbsp;
                <span>
                    <button v-if="editDevice == true" type="button" class="btn btn-info rounded-5 border mt-1"
                        @click="changeStateEdit()">
                        <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Press to disable edit devices</button>

                    <button v-else type="button" class="btn btn-light rounded-5 border mt-1" @click="changeStateEdit()">
                        <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Press to enable edit devices</button>


                    <button v-if="listUsers == true" type="button" class="btn btn-dark rounded-5  border mt-1"
                        @click="changeStateListUser()">
                        <font-awesome-icon :icon="['fas', 'users']" /> List Users</button>
                    <button v-else type="button" class="btn btn-light rounded-5  border mt-1"
                        @click="changeStateListUser()">
                        <font-awesome-icon :icon="['fas', 'users']" /> List Users</button></span>
            </div>

            <div class="row">

                <div class="col-xl-3 col-lg-4  col-md-6 col-sm-6 col-12 mt-3" v-for="(item, index) in items"
                    :key="index">
                    <router-link :to="'/inside/device/'+item.id" style="text-decoration: none;">
                        <div class="card rounded-5 gr-card border-0 ">
                            <div class="card-body">
                                <div class=" px-3 text-white">
                                    <div style="font-size: 16px;">
                                        <font-awesome-icon :icon="['fas', 'temperature-low']"
                                            style="font-size: 24px;" /> Name {{ item.name }}</div>
                                    <div style="font-size: 12px;">Serial Number: {{ item.SN}}</div>
                                    <div style="font-size: 13px;">Type:{{ item.Category }}</div>
                                    <!-- <div>{{ item.Description }}</div> -->
                                    <div style="font-size: 13px; color: #FFB205;height: 35px;">Description: {{ item.Description }}
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-12">
                                            <!-- <div class="dot-container border">
                                                <span style="font-size: 16px;">Status</span>  <span class="dot-on"></span> <span>sss</span>
                                            </div> -->
                                            <div class="row">

                                                <div class="dot-container" v-if="item.status == 'true'">
                                                    <span style="font-size: 16px;"> Status&nbsp;</span> <span
                                                        class="dot-on"></span> <span>&nbsp;Online</span>
                                                </div>
                                                <div class="dot-container" v-if="item.status == 'false'">
                                                    <span style="font-size: 16px;"> Status&nbsp;</span> <span
                                                        class="dot-off"></span> <span>&nbsp;Offline</span>
                                                </div>
                                                <!-- <div v-if="item.status == 'true'" class="col-12 dot-container" style="font-size: 16px;">
                                                    Status <span style="color: #48E645;"><span class="dot-on"></span>
                                                        Online</span></div>
                                                <div v-if="item.status == 'false'" class="col-12 dot-container border" style="font-size: 16px;">
                                                    Status <span style="color: rgb(254, 58, 58);"><span class="dot-off"></span>
                                                        Offline</span></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <div class="row justify-content-start mt-1 " v-if="editDevice  == true">
                        <div class="col-1"></div>
                        <button type="button" class=" col-3 ms-1 btn btn-primary rounded-4" style="font-size: 11px;">
                            <router-link :to="'/adddevice/?did='+item.id+'&edit=edit'" style="text-decoration: none;">
                                <font-awesome-icon :icon="['fas', 'pen-to-square']" style="color: white;" /> <span
                                    style="color: white;">Edit</span>
                            </router-link>
                        </button>

                        <button type="button" class=" col-3 ms-1 btn btn-danger rounded-4" style="font-size: 11px;"
                            data-bs-toggle="modal" data-bs-target="#deleteLocationModal"
                            @click="delete_id=item.id,delete_name=item.name">
                            <font-awesome-icon :icon="['fas', 'trash']" /> Delete</button>
                    </div>
                </div>
            </div>

            <div class="h5 mt-5">
                <font-awesome-icon :icon="['fas', 'bars']" style="font-size: 22px;" /> Other Device List</div>
            <div v-if="itemsE.length==0" class="text-start mt-3" style="color: gray;"> No Other Devices.</div>
            <div class="row">
                <div class="col-xl-3 col-lg-4  col-md-6 col-sm-6 col-12 mt-3" v-for="(item, index) in itemsE"
                    :key="index">
                    <router-link to="/inside/device" style="text-decoration: none;">
                        <div class="card rounded-5  border-0 " style="height: 163px; background-color: #E6E6E6;">
                            <div class="card-body">
                                <div class=" text-dark">
                                    <div style="font-size: 18px;">{{ item.name }}</div>
                                    <div style="font-size: 16px;">{{ item.des }}</div>
                                    <div style="font-size: 16px;">Type:{{ item.type }}</div>
                                    <div class="mt-3" style="font-size: 16px; color: #FFB205;"> --
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12" style="font-size: 18px;">Status <span
                                                        style="color: #48E645;"><span class=""></span> Online</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="col-12" v-if="listUsers==true">
                <div class="h5 mt-5">
                    <font-awesome-icon :icon="['fas', 'users']" /> User In Location</div>
                <div class="h6 mt-4"> List Users In Location</div>
                <table class="col-12 mt-2 border rounded-5">
                    <tr style="background-color:  #dddddd;">
                        <th style="font-weight: bold;">Email</th>
                        <!-- <th style="font-weight: bold;">Zone</th> -->
                        <th style="font-weight: bold;">Role</th>
                        <th style="font-weight: bold;">Create At</th>
                        <th v-if="role!='User'" class="text-center" style="font-weight: bold;">Remove User</th>
                    </tr>
                    <tr class="border" v-for="(item, index) in itemUsersLocation" :key="index">
                        <td>{{ item.email }}</td>
                        <td>{{ item.role }}</td>
                        <td>{{ item.updatedAt }}</td>
                        <td v-if="role!='User'" class="text-center" style="width: 200px;">
                            <!-- <button type="button"
                                class="btn btn-primary"><span style="font-size: 12px;">Edit</span></button>  -->
                            <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                                data-bs-target="#deleteUserModal" @click=" u_delete_id=item.id,
        u_delete_email=item.email">
                                <span style="font-size: 12px;">Delete</span>
                            </button>
                        </td>
                    </tr>
                </table>

            </div>

        </div>
        <!-- Modal -->
        <div class="modal fade" id="deleteLocationModal" tabindex="-1" aria-labelledby="deleteLocationModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="deleteLocationModalLabel">Delete Device</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        Confirm Delete Device
                        <div>Name: {{ delete_name }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-danger" @click="delDeviceOne(delete_id)"
                            data-bs-dismiss="modal">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="deleteUserModal" tabindex="-1" aria-labelledby="deleteUserModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="deleteUserModalLabel">Delete User</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        Confirm Delete User
                        <div>
                            <font-awesome-icon :icon="['fas', 'envelope']" /> Email {{ u_delete_email }}</div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                            @click="delUserOne(u_delete_id)">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="adduserModal" tabindex="-1" aria-labelledby="adduserModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="adduserModalLabel">
                            <font-awesome-icon :icon="['fas', 'user-plus']" /> Invite User</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12 mt-2">
                            <div class="row">
                                <span class="col-4 text-center">
                                    <font-awesome-icon :icon="['fas', 'envelope']" /> Invite Email </span>
                                <input v-model="addUser.email" type="text" class="col-8 rounded-4  border-1"
                                    style="height: 34px;">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            <font-awesome-icon :icon="['fas', 'xmark']" /> Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="inviteUser()">
                            <font-awesome-icon :icon="['fas', 'plus']" /> Invite</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="successModal" aria-hidden="true" aria-labelledby="successModalLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div style="height: 100px;"></div>
                        <div class="text-center h3 text-success">
                            <font-awesome-icon :icon="['fas', 'circle-check']" /> Success</div>
                        <div style="height: 100px;"></div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success" data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>

        </div>
        <!-- Modal -->
        <div class="modal fade" id="changeNotiEmailModal" tabindex="-1" aria-labelledby="changeNotiEmailModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="changeNotiEmailModalLabel">Notification Setting</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="dataProject!=null">
                        <div class="col-12 mt-2 ">
                            <div class="row mt-2">

                                <span class="col-4 text-end" style="font-size: 14px;">Line Token
                                    <font-awesome-icon :icon="['fab', 'line']" />: </span>
                                <input v-model="dataProject['LineTokens']" type="text" class="col-7 rounded-4  border-1"
                                    style="height: 36px;">
                            </div>
                            <hr>
                            <div class="mb-3">List Email <font-awesome-icon :icon="['fas', 'envelope']" /> </div>
                            <!-- {{  dataProject['EmailTokens'] }} -->
                            <div class="row mt-2" v-for="(item, index) in dataProject['EmailTokens']" :key="index" >
                                <span class="col-4 text-end" style="font-size: 14px;">
                                    <font-awesome-icon :icon="['fas', 'envelope']" /> Email {{ index+1 }}: </span>
                                <input v-model="dataProject['EmailTokens'][index]" type="text"
                                    class="col-6 rounded-4  border-1" style="height: 36px;"> 
                                    &nbsp;
                                    <!-- <button type="button" class="btn  btn-outline-primary col-1 rounded-5" v-if="index+1>=dataProject['EmailTokens'].length">+</button> -->
                                    
                                    <button type="button" class="btn  btn-outline-danger col-1 rounded-5" @click="dataProject['EmailTokens'].splice(index,1)">x</button>
                                    <!-- <div class="text-end pe-4 pt-1" style="font-size: 12px;" v-if="index+1>=dataProject['EmailTokens'].length">Add Email&nbsp;&nbsp;</div> -->
                            </div>
                            <div class="row mt-2"   v-if="dataProject['EmailTokens'].length+1<=10">
                                <span class="col-4 text-end" style="font-size: 14px;">
                                  </span>
                                    <div class="col-6 text-end pt-2">Add New Email</div>
                                    &nbsp;
                                    <button type="button" class="btn  btn-outline-primary col-1 rounded-5" @click="dataProject['EmailTokens'].push('')">+</button>
                                    <!-- <div class="text-end pe-4 pt-1" style="font-size: 12px;" >Add Email&nbsp;&nbsp;</div> -->
                            </div>

                            <!-- <div class="row mt-2">
                                <span class="col-4 text-end" style="font-size: 14px;">
                                    <font-awesome-icon :icon="['fas', 'envelope']" /> Email 2: </span>
                                <input v-model="dataProject['EmailTokens'][1]" type="text"
                                    class="col-7 rounded-4  border-1" style="height: 36px;">
                            </div>
                            <div class="row mt-2">
                                <span class="col-4 text-end" style="font-size: 14px;">
                                    <font-awesome-icon :icon="['fas', 'envelope']" /> Email 3: </span>
                                <input v-model="dataProject['EmailTokens'][2]" type="text"
                                    class="col-7 rounded-4  border-1" style="height: 36px;">
                            </div> -->

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="EditProject()"
                            data-bs-dismiss="modal">Save Change</button>
                    </div>
                </div>
            </div>
        </div>
    </body>

</template>

<script>
    import {
        useMQTT
    } from 'mqtt-vue-hook'
    const mqttHook = useMQTT()
    var protocol = 'wss'
    var host = 'fzm-ws.sgroup.io'
    var port = 443
    import {
        ref,
        onMounted
    } from 'vue';
    import {
        Modal
    } from 'bootstrap';
    import axios from 'axios'
    import Navbar from "@/components/navbar_web.vue";
    export default {
        name: 'App',
        components: {
            Navbar
        },
        setup() {
            const modalElement = ref(null);
            let modalInstance = null;

            const showModal = () => {
                if (modalInstance) {
                    modalInstance.show();
                }
            };

            onMounted(() => {
                modalElement.value = document.getElementById('successModal');
                modalInstance = new Modal(modalElement.value);
            });

            return {
                showModal
            };
        },
        data() {
            return {
                exporrtEmail: [

                ],
                dataProject: null,
                subscribedTopics: [],
                u_delete_id: '',
                u_delete_email: '',
                role: '',
                delete_name: null,
                delete_id: null,
                addUser: {
                    email: null
                },
                listUsers: false,
                itemUsersLocation: [],
                editDevice: false,
                test: "1234",
                items: [],
                itemsE: [],
            }
        },
        unmounted() {
            this.cleanup();
        },
        methods: {
            async EditProject() {
                try {
                    var load = await axios.put('https://fzm-api.sgroup.io/api/project', {
                        id: this.dataProject['id'],
                        // name: this.itemOne.name,
                        // Description: this.itemOne.Description,
                        // Country: this.itemOne.Country,
                        // Address: this.itemOne.Address,
                        // Province: this.itemOne.Province,
                        // ZipCode: this.itemOne.ZipCode,
                        // SubDistrict: this.itemOne.SubDistrict,
                        // City: this.itemOne.City,
                        // LineTokens: this.itemOne.LineTokens,
                        EmailTokens: this.dataProject['EmailTokens'],
                        LineTokens: this.dataProject['LineTokens']
                        // Latitude: this.itemOne.Latitude,
                        // Longitude: this.itemOne.Longitude,
                        // userId: localStorage.getItem('user_id'),
                        // zoneId: this.itemOne.zoneId
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    // this.$router.go(-1);
                    console.log(load.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.EditProject();
                    } else {
                        console.error(error);
                    }
                }
            },

            findCallMQTT(pre_items) {
                for (let index = 0; index < pre_items.length; index++) {
                    // this.items[index].status = this.CallMQTTONE(this.items[index]['SN']);
                    console.log(index);
                }
                // console.log(this.items);
            },
            CallMQTTONE(pre_items) {
                mqttHook.connect(`${protocol}://${host}:${port}/ws`, {
                    username: 'frontend',
                    password: '8w95BXTq1gZH',
                    keepalive: 60,
                    connectTimeout: 4000,
                });

                this.subscribedTopics = [];

                pre_items.forEach((item, index) => {
                    console.log(item.SN);
                    const topic = `@msg/FZMIOT001/${item.SN}`;

                    mqttHook.subscribe([topic], 1, {
                        properties: {},
                    }, () => {
                        console.log('subscribed!');
                    });

                    mqttHook.registerEvent(topic, (_topic, message) => {
                        const messageString = message.toString('utf8');
                        const oneData = JSON.parse(messageString);
                        console.log("oneData.status");
                        console.log(oneData.status);
                        if (oneData.status === true) {
                            this.items[index]["status"] = 'true';
                        }
                    }, 'mqtt-client-web'); // clientID

                    this.subscribedTopics.push(topic);
                });




                // Example usage: Call cleanup when you need to unsubscribe and unregister events
                // cleanup();
            },
            // Function to unsubscribe and unregister events
            cleanup() {
                this.subscribedTopics.forEach(topic => {
                    console.log(topic)
                    mqttHook.unSubscribe(topic);
                    mqttHook.unRegisterEvent(topic);
                });
            },

            async delDeviceOne(id) {
                try {
                    var load = await axios.delete('https://fzm-api.sgroup.io/api/device/' + id, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    });
                    console.log('API response:', load.data); // Log the API response data
                    this.getDeviceAll();
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.delDeviceOne(id);
                    } else {
                        console.error(error);
                    }
                }
            },
            async getUsersPid() {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/users/project', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'projectid': this.$route.params.id
                        }
                    })
                    this.itemUsersLocation = load.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getUsersPid();
                    } else {
                        console.error(error);
                    }
                }

            },
            async getDeviceAll() {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/device', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'projectid': this.$route.params.id
                        }
                    })
                    this.items = load.data;
                    if (load.status == 200) {
                        console.log(555)
                        this.CallMQTTONE(load.data);

                    }
                    console.log(load.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getDeviceAll();
                    } else {
                        console.error(error);
                    }
                }

                // localStorage.setItem('data', JSON.stringify(load.data));
            },
            async delUserOne(id_user) {
                try {
                    const params = {
                        projectId: this.$route.params.id,
                        userId: id_user
                    };
                    var load = await axios.delete('https://fzm-api.sgroup.io/api/project/users', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        },
                        data: params
                    });
                    console.log('API response:', load.data);
                    this.getUsersPid();
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.delUserOne(id_user);
                    } else {
                        console.error(error);
                    }
                    console.error('Error fetching data:', error);
                }
            },
            async inviteUser() {
                try {
                    var load = await axios.post('https://fzm-api.sgroup.io/api/project/users', {
                        email: [this.addUser.email],
                        projectId: this.$route.params.id,
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    console.log(load.data);
                    if (load.status == 200) {
                        this.showModal();
                        this.getUsersPid();

                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.inviteUser();
                    } else {
                        console.error(error);
                    }
                }

            },
            async getDataProjectFormID(id) {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/project/' + id, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    this.dataProject = load.data;
                    console.log(load.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getDataProjectFormID(id);
                    } else {
                        console.error(error);
                        this.$router.go(0);
                        console.log("reload")
                    }
                }

            },

            changeStateEdit() {
                if (this.editDevice == false) {
                    this.editDevice = true;
                } else {
                    this.editDevice = false;
                }
            },
            changeStateListUser() {
                if (this.listUsers == false) {
                    this.listUsers = true;
                } else {
                    this.listUsers = false;
                }
            }
        },
        created() {
       
            this.getDeviceAll();
            if (this.$route.params.id!=null) {
                this.getDataProjectFormID(this.$route.params.id);
                console.log("success");
                console.log(this.$route.params.id);
            }
            this.role = localStorage.getItem('role');
            this.getUsersPid();

           
        },
        mounted() {

  // Any additional logic needed specifically after the DOM has been mounted
}


    }
</script>
<style>
    .gr-card {
        background-image: linear-gradient(90deg, #5DE0E6 0%, #004AAD 100%);
        color: white;
    }

    .btn-add {
        background-image: linear-gradient(90deg, #5DE0E6 0%, #004AAD 100%);
        color: white;
    }

    .dot-container {
        display: flex;
        align-items: center;
        /* Centers vertically */
    }

    .dot-on {
        /* margin-top:20 ; */
        height: 20px;
        width: 20px;
        background-color: #49fe77;
        border-radius: 50%;
        display: inline-block;
    }

    .dot-off {
        /* margin-top:20 ; */
        height: 20px;
        width: 20px;
        background-color: rgb(254, 58, 58);
        border-radius: 50%;
        display: inline-block;
    }
</style>
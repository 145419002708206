<template>

    <div class="row">
        <div class="col-xl-12" style="height: 100vh; background-color: #F8F9FA;">
            <div class="row justify-content-center">
                <div style='height: 160px;'></div>
                <div class="col-xl-4 col-lg-4 col-md-8 col-sm-11 col-11">
                    <div class="card px-3 py-4    border-0 rounded-4">
                        <div style='height: 20px;'></div>
                        <form>
                            <div class="h4 text-center">
                                Forgot Password ?</div>
                            <div class="h5 text-center mb-2" style="color: #2368C6;font-size: 18px;font-weight: bold;">
                                FREEZER MONITOR SYSTEM
                            </div>

                            <div class="col-12 mt-4">
                                <div class="row">
                                    <span class="col-4 text-end">
                                        <font-awesome-icon :icon="['fas', 'envelope']" /> Email </span>
                                    <input v-model="email" :type="email" class="col-7 rounded-4 border-1"
                                        style="height: 36px;">
                                </div>
                            </div>
                            <div class="text-center mt-1 ms-3" style="font-size: 14px;"> *** Use registered email in this platform. *** </div>
                            <div v-if="errorMessage" class="col-12 mt-2">
                                <div class="row">
                                    <div class="col-3 text-end"></div>
                                    <span class="col-8 text-danger">{{ errorMessage }}</span>
                                </div>
                            </div>
                            <div class="text-center mt-5 mx-5"> <button type="button" data-mdb-button-init
                                    data-mdb-ripple-init class="btn btn-primary btn-block mb-4 col-12 rounded-4"
                                    @click="forgotpass()">
                                    Forgot password || Check your email.</button></div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="successModal" aria-hidden="true" aria-labelledby="successModalLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="successModalLabel">Forgot password</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">Check Your email and change password.</div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" @click="gotologin()" data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>



</template>

<script>
    import {
        ref,
        onMounted
    } from 'vue';
    import {
        Modal
    } from 'bootstrap';
    import axios from 'axios'
    export default {
        name: 'App',
        components: {

        },
        setup() {
            const modalElement = ref(null);
            let modalInstance = null;

            const showModal = () => {
                if (modalInstance) {
                    modalInstance.show();
                }
            };

            onMounted(() => {
                modalElement.value = document.getElementById('successModal');
                modalInstance = new Modal(modalElement.value);
            });

            return {
                showModal
            };
        },
        data() {
            return {
                passwordFieldType: 'password',
                errorMessage: '',
                resetId: '',
                email: '',
                fname: '',
                lname: '',
                password: '',
                re_password: '',

            }
        },
        watch: {
            password() {
                this.checkPassword();
            },
            re_password() {
                this.checkPassword();
            }
        },
        methods: {
            togglePasswordVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            },
            checkPassword() {
                if (this.password.length < 8) {
                    this.errorMessage = 'Password must be at least 8 characters.';
                } else if (this.password !== this.re_password) {
                    this.errorMessage = 'Passwords do not match.';
                } else {
                    this.errorMessage = '';
                }
            },
            async forgotpass() {
                var load = await axios.post('https://fzm-api.sgroup.io/api/users/forgotpassword', {
                    email: this.email
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                console.log(load.data);
                if (load.status == 200) {
                    this.showModal();
                }
            },
        },
        created() {}


    }
</script>

<style>
</style>
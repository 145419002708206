<template>

    <body>
        <div class="row">
            <Sidebar_web></Sidebar_web>
            <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 bg-white" style="min-height: 100vh;">
                <div class="row justify-content-center">
                    <div style='height: 50px;'></div>
                    <div class="col-xl-5 col-lg-5 col-md-7 col-sm-9 col-12">
                        <div class="card  py-4  border-0 rounded-4">
                            <div style='height: 20px;'></div>
                            <form>
                                <div v-if="  p_edit=='edit'" class="h4 text-center">Edit Location </div>
                                <div v-else class="h4 text-center">+ Add Location</div>

                                <div class="col-12">
                                    <div class="row">
                                        <span class="col-3 text-end">Name </span>
                                        <input v-model="itemOne.name" type="text" class="col-8 rounded-4  border-1"
                                            style="height: 35px; max-width: 290px;">
                                    </div>
                                </div>

                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-3 text-end">Description</span>
                                        <input v-model="itemOne.Description" type="text"
                                            class="col-8 rounded-4  border-1" style="height: 35px; max-width: 290px;">
                                    </div>
                                </div>

                                <div class="col-12" v-if="preZone==1||preZone==2">
                                    <div class="row mt-2">
                                        <span class="col-3 text-end">Area</span>
                                        <select v-model="itemOne.zoneId" class="col-9 form-select rounded-4 "
                                            aria-label="Default select example" style="max-width: 290px;">
                                            <option v-for="(item, index) in itemZones" :key="index" :value="item.id">
                                                {{item.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="row mt-2">
                                        <span class="col-3 text-end">Country</span>
                                        <select v-model="itemOne.Country" class="col-9 form-select rounded-4 "
                                            aria-label="Default select example" style="max-width: 290px;">
                                            <option>Thailand</option>
                                            <option>Lao</option>
                                        </select>
                                    </div>
                                </div>

                                <div v-if="itemOne.Country=='Thailand'">

                                    <div class="col-12 mt-2">
                                        <div class="row mt-2" v-if="itemOne.Country=='Thailand'">
                                            <span class="col-3 text-end">Province</span>
                                            <select v-model="itemOne.Province" class="col-9 form-select rounded-4 "
                                                aria-label="Default select example" style="max-width: 290px;">
                                                <option v-for="(item, index) in listProvince" :key="index"
                                                    :value="item.id">
                                                    {{item.name_th}}</option>
                                            </select>
                                        </div>
                                        <div class="row" v-else>
                                            <span class="col-3 text-end">Province</span>
                                            <input type="text" v-model="itemOne.Province"
                                                class="col-8 rounded-4  border-1" style="height: 35px; max-width: 290px;">
                                        </div>
                                    </div>

                                    <div class="col-12 mt-2">
                                        <div class="row">
                                            <span class="col-3 text-end">Postcode</span>
                                            <input type="text" v-model="itemOne.ZipCode"
                                                class="col-8 rounded-4  border-1" style="height: 35px; max-width: 290px;">
                                        </div>
                                    </div>

                                    <div class="col-12 mt-2">
                                        <div class="row">
                                            <span class="col-3 text-end">District</span>
                                            <input type="text" v-model="itemOne.City" class="col-8 rounded-4  border-1"
                                                style="height: 35px; max-width: 290px;">
                                        </div>
                                    </div>

                                    <div class="col-12 mt-2">
                                        <div class="row">
                                            <span class="col-3 text-end">Sub-District</span>
                                            <input type="text" v-model="itemOne.SubDistrict"
                                                class="col-8 rounded-4  border-1" style="height: 35px; max-width: 290px;">
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="itemOne.Country=='Lao'">
                                    <div class="col-12 mt-2">
                                        <div class="row mt-2">
                                            <span class="col-3 text-end">City</span>
                                            <input type="text" v-model="itemOne.Province"
                                                class="col-8 rounded-4  border-1" style="height: 35px; max-width: 290px;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-3 text-end">Address</span>
                                        <input type="text" v-model="itemOne.Address" class="col-8 rounded-4  border-1"
                                            style="height: 70px;  max-width: 290px;">
                                    </div>
                                </div>

                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-3 text-end">Latitude</span>
                                        <input type="text" v-model="itemOne.Latitude" class="col-8 rounded-4  border-1"
                                            style="height: 35px; max-width: 290px;">
                                    </div>
                                </div>

                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-3 text-end">Longitude</span>
                                        <input type="text" v-model="itemOne.Longitude" class="col-8 rounded-4  border-1"
                                            style="height: 35px; max-width: 290px;">
                                    </div>
                                </div>

                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-3 text-end">Notification Email</span>
                                        <button type="button" class="btn btn-warning rounded-5 text-white"
                                            style="font-size: 12px; width: 223px;height: 32px;" data-bs-toggle="modal"
                                            data-bs-target="#changeNotiEmailModal">Set Email</button>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-3 text-end">Line Token</span>
                                        <input type="text" v-model="itemOne.LineTokens"
                                            class="col-8 rounded-4  border-1" style="height: 35px; max-width: 290px;">
                                    </div>
                                </div>

                                <!-- Submit button -->
                                <div v-if=" p_edit=='edit'" class="text-center mt-5 mx-2"> <button type="button"
                                        data-mdb-button-init data-mdb-ripple-init
                                        class="btn btn-primary btn-block mb-4 col-12 rounded-4 " @click="EditProject()">
                                        <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Edit
                                        Location</button></div>
                                <div v-else class="text-center mt-5 mx-2"> <button type="button" data-mdb-button-init
                                        data-mdb-ripple-init class="btn btn-primary btn-block mb-4 col-12 rounded-4"
                                        @click="AddNewProject()">+ Add
                                        Location</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="changeNotiEmailModal" tabindex="-1" aria-labelledby="changeNotiEmailModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="changeNotiEmailModalLabel">Notification Email</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" v-if="itemOne.EmailTokens!=null">
                            <div class="col-12 mt-1 ">
                                <div class="mb-3">List Email <font-awesome-icon :icon="['fas', 'envelope']" /> </div>
                            <!-- {{  itemOne.EmailTokens }} -->
                            <div class="row mt-2" v-for="(item, index) in itemOne.EmailTokens" :key="index" >
                                <span class="col-4 text-end" style="font-size: 14px;">
                                    <font-awesome-icon :icon="['fas', 'envelope']" /> Email {{ index+1 }}: </span>
                                <input v-model="itemOne.EmailTokens[index]" type="text"
                                    class="col-6 rounded-4  border-1" style="height: 36px;"> 
                                    &nbsp;
                                    <!-- <button type="button" class="btn  btn-outline-primary col-1 rounded-5" v-if="index+1>=itemOne.EmailTokens.length">+</button> -->
                                    
                                    <button type="button" class="btn  btn-outline-danger col-1 rounded-5" @click="itemOne.EmailTokens.splice(index,1)">x</button>
                                    <!-- <div class="text-end pe-4 pt-1" style="font-size: 12px;" v-if="index+1>=itemOne.EmailTokens.length">Add Email&nbsp;&nbsp;</div> -->
                            </div>

                            <div class="row mt-2" v-if="itemOne.EmailTokens.length+1<=10">
                                <span class="col-4 text-end" style="font-size: 14px;">
                                  </span>
                                    <div class="col-6 text-end pt-2">Add New Email</div>
                                    &nbsp;
                                    <button type="button" class="btn  btn-outline-primary col-1 rounded-5" @click="itemOne.EmailTokens.push('')">+</button>
                                    <!-- <div class="text-end pe-4 pt-1" style="font-size: 12px;" >Add Email&nbsp;&nbsp;</div> -->
                            </div>
                              
                                <!-- <div class="row mt-2">
                                    <span class="col-4 text-end" style="font-size: 14px;">Email 1: </span>
                                    <input v-model="itemOne.EmailTokens[0]" type="text"
                                        class="col-7 rounded-4  border-1" style="height: 36px;">
                                </div>
                                <div class="row mt-2">
                                    <span class="col-4 text-end" style="font-size: 14px;">Email 2: </span>
                                    <input v-model="itemOne.EmailTokens[1]" type="text"
                                        class="col-7 rounded-4  border-1" style="height: 36px;">
                                </div>
                                <div class="row mt-2">
                                    <span class="col-4 text-end" style="font-size: 14px;">Email 3: </span>
                                    <input v-model="itemOne.EmailTokens[2]" type="text"
                                        class="col-7 rounded-4  border-1" style="height: 36px;">
                                </div> -->

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>

</template>

<script>
    import axios from 'axios'
    import Sidebar_web from '@/components/sidebar_web.vue'
    export default {
        name: 'App',
        components: {
            Sidebar_web
        },
        data() {
            return {
                preZone: null,
                itemZones: [],
                p_edit: null,
                p_id: null,
                listProvince: [],
                itemOne: {
                    EmailTokens: null,
                    id: null,
                    name: null,
                    Country: null,
                    Address: null,
                    Province: null,
                    ZipCode: null,
                    SubDistrict: null,
                    City: null,
                    Description: null,
                    LineTokens: null,
                    Latitude: null,
                    Longitude: null,
                    zoneId: null,
                    createdAt: null,
                    UserProjects: [{
                        userId: null,
                        user: {
                            id: null,
                            email: null
                        }
                    }]
                },
                items: [{
                    name: "Freezer Thailand",
                    des: "Demo Freezer From Local Location",
                    number_device: 1,
                }, ]
            }
        },
        methods: {
            async getZoneAll() {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/zone/all', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    this.itemZones = load.data;
                    console.log(load.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getZoneAll();
                    } else {
                        console.error(error);
                    }
                }


            },
            async AddNewProject() {
                try {
                    var load = await axios.post('https://fzm-api.sgroup.io/api/project/add', {
                        name: this.itemOne.name,
                        Description: this.itemOne.Description,
                        Country: this.itemOne.Country,
                        Address: this.itemOne.Address,
                        Province: this.itemOne.Province,
                        ZipCode: this.itemOne.ZipCode,
                        SubDistrict: this.itemOne.SubDistrict,
                        City: this.itemOne.City,
                        LineTokens: this.itemOne.LineTokens,
                        EmailTokens: this.itemOne.EmailTokens,
                        Latitude: this.itemOne.Latitude,
                        Longitude: this.itemOne.Longitude,
                        userId: localStorage.getItem('user_id'),
                        zoneId: this.itemOne.zoneId
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    // this.$router.go(-1);
                    this.$router.replace('/')
                    console.log(load.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.AddNewProject();
                    } else {
                        console.error(error);
                    }
                }
            },

            async EditProject() {
                try {
                    var load = await axios.put('https://fzm-api.sgroup.io/api/project', {
                        id: this.itemOne.id,
                        name: this.itemOne.name,
                        Description: this.itemOne.Description,
                        Country: this.itemOne.Country,
                        Address: this.itemOne.Address,
                        Province: this.itemOne.Province,
                        ZipCode: this.itemOne.ZipCode,
                        SubDistrict: this.itemOne.SubDistrict,
                        City: this.itemOne.City,
                        LineTokens: this.itemOne.LineTokens,
                        EmailTokens: this.itemOne.EmailTokens,
                        Latitude: this.itemOne.Latitude,
                        Longitude: this.itemOne.Longitude,
                        userId: localStorage.getItem('user_id'),
                        zoneId: this.itemOne.zoneId
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    // this.$router.go(-1);
                    this.$router.replace('/')
                    console.log(load.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.EditProject();
                    } else {
                        console.error(error);
                    }
                }
            },

            async getDataProvince() {
                try {
                    var load = await axios.get(
                    'https://fzm-api.sgroup.io/api/thai-province-data/all/provinces', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('user_id')
                        }
                    })
                    this.listProvince = load.data;
                    console.log(load.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getDataProvince();
                    } else {
                        console.error(error);
                    }
                }
            },

            async getDataProjectFormID(id) {
           try {
            var load = await axios.get('https://fzm-api.sgroup.io/api/project/' + id, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('accessToken')
                    }
                })
                this.itemOne = load.data;
                console.log(load.data);
           } catch (error) {
            console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getDataProjectFormID(id);
                    } else {
                        console.error(error);
                    }
           }
            },

            setLocalVar(DataProject) {
                var projectList = localStorage.getItem('project');
                console.log(projectList);
                if (projectList != null) {
                    console.log("Not null")
                    var Load = JSON.parse(projectList);
                    Load.push(DataProject)
                    localStorage.setItem('project', JSON.stringify(Load));
                } else {
                    var Load2 = [DataProject];
                    localStorage.setItem('project', JSON.stringify(Load2));
                    console.log("null trrr")
                }
                this.$router.replace('/')
            }
        },
        created() {

            this.getZoneAll();
            this.p_id = this.$route.query.pid || '';
            this.p_edit = this.$route.query.edit || '';
            this.getDataProvince();

            if (this.p_edit == 'edit') {
                this.getDataProjectFormID(this.p_id)
            } else {
                this.itemOne = []
                this.itemOne.EmailTokens = [''];
            }
            this.preZone = localStorage.getItem('zone_id');
            if (this.preZone == 1 || this.preZone == 2) {
                console.log(this.preZone)
                this.itemOne.zoneId = null;
            } else{
                this.itemOne.zoneId = this.preZone;
            }
            // else{}
            // if(preZone ==1 ||preZone ==2){
            //     this.itemOne.
            // }
        }


    }
</script>

<style>
</style>
import axios from "axios";
let router = null;
export const globalFunctions = {
  setRouter(r) {
    router = r;
  },
  
  async newAccToken() {
    try {
      var accessToken = localStorage.getItem('accessToken');
      var refreshToken = localStorage.getItem('refreshToken');
      const response = await axios.post('https://fzm-api.sgroup.io/auth/refresh', {
        accessToken:accessToken,
        refreshToken:refreshToken
      }, {
        headers: {
          'Content-Type': 'application/json',
          'token':refreshToken
        }
      });

      console.log(response.data);
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return response.data;
    } catch (error) {
      localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      if (router) {
        router.push('/login'); // Navigate to login page
      }
      console.error('Error refreshing token:', error);
      throw error;
    }
  },
};

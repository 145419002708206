import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
// library.add(fab);
import VueScreen from "vue-screen";

import VueApexCharts from "vue3-apexcharts";

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import { globalFunctions } from './globalFunctions';
const app = createApp(App);
globalFunctions.setRouter(router);
app.config.globalProperties.$globalFunctions = globalFunctions;

app.component('EasyDataTable', Vue3EasyDataTable).component("font-awesome-icon", FontAwesomeIcon).use(VueApexCharts).use(router).use(VueScreen).mount('#app')

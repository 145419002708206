<template>
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 pt-3 bg-light "  >
        <div class="">
            <!-- {{ $screen.width }} -->
            <!-- <div v-if="$screen.width>765">This is a large screen</div>
            <div v-else>This is a small screen</div> -->
            <div class="">
                <img class="rounded-4" v-if="$screen.width>765"
                    src="https://richmond-craft-production.s3.amazonaws.com/content/stores/logos/fridge-freezer-logo.jpg"
                    alt="" width="60">
            </div>

            <div style="text-decoration: none;" class="row">
                <router-link to="/" class="navbar-brand  col-9" href="#"
                    style="font-size: 17px;font-weight: bold;color: #3B3B3B;">
                    <div class="ms-2" style="font-size: 12px;color: black;">Welcome to </div>&nbsp; Monitoring Dashboard
                    <!-- <font-awesome-icon :icon="['fas', 'desktop']" /> -->
                </router-link>
                <div v-if="$screen.width<765" class=" col-3">
                    <button v-if="side_state==true" type="button"
                        class="btn  btn-secondary  text-white text-start ps-2  text-center col-12 rounded-5 shadow"
                        @click="saveState(false)" style="color: black;font-size: 13px; height: 40px; max-width: 85px;">
                        <font-awesome-icon :icon="['fas', 'xmark']" /> Menu
                    </button>
                    <button v-if="side_state==false" type="button"
                        class="btn   text-white  text-start ps-2  text-center col-12 rounded-5 shadow" @click="saveState(true)"
                        style="color: black;font-size: 13px; height: 40px; max-width: 85px; background-color: #2268C6;">
                        <font-awesome-icon :icon="['fas', 'bars']" /> Menu
                    </button>
                </div>
            </div>

            <div class="mt-2"></div>


            <div v-if="side_state==true" class="px-2 mt-3">
                <button v-if="lastStateColor == 1" type="button" class="btn  col-12 text-start ps-2 mt-1 rounded-4"
                    @click="locationVisible = !locationVisible; lastStateColor = setLastState(1)"
                    style="background-color: rgb(57 121 205);color: white;">
                    <font-awesome-icon :icon="['fas', 'earth-americas']" /> Location
                </button>
                <button v-else type="button" class="btn btn-light  col-12 text-start ps-2 mt-1 rounded-4"
                    @click="locationVisible = !locationVisible; lastStateColor = setLastState(1)">
                    <font-awesome-icon :icon="['fas', 'earth-americas']" /> Location
                </button>

                <div v-if="role!='User'">
                    <div v-if="locationVisible ==true">
                        <div class="ps-4 col-12 mt-1">
                            <router-link @click="setLastState(1)"  to="/" v-if="$route.fullPath=='/'" 
                                style="text-decoration: none; font-size: 16px;color: #2368C6;">
                                <font-awesome-icon :icon="['fas', 'list']" /> Location All</router-link>
                            <router-link @click="setLastState(1)"  to="/" v-else style="text-decoration: none; font-size: 16px;color:#434343;" >
                                <font-awesome-icon :icon="['fas', 'list']" /> Location All</router-link>
                        </div>
                        <div class="ps-4 col-12 mt-1">
                            <router-link @click="setLastState(1)"  to="/map" v-if="$route.path=='/map'"
                                style="text-decoration: none; font-size: 16px;color: #2368C6;">
                                <font-awesome-icon :icon="['fas', 'map-location-dot']" /> Location MAP</router-link>
                            <router-link @click="setLastState(1)"  to="/map" v-else
                                style="text-decoration: none; font-size: 16px;color: #434343;">
                                <font-awesome-icon :icon="['fas', 'map-location-dot']" /> Location MAP</router-link>
                        </div>
                        <div class="ps-4 col-12 mt-1">
                            <router-link @click="setLastState(1)"  to="/add" v-if="$route.path=='/add'"
                                style="text-decoration: none; font-size: 16px;color:#2368C6;">
                                <font-awesome-icon :icon="['fas', 'plus']" /> Add Location</router-link>
                            <router-link @click="setLastState(1)"  to="/add" v-else style="text-decoration: none; font-size: 16px;color:#434343">
                                <font-awesome-icon :icon="['fas', 'plus']" /> Add Location</router-link>
                        </div>
                        <!-- <div class="ps-4 col-12 mt-1">
                <router-link to="/?edit=edit" v-if="$route.href=='/?edit=edit'"
                    style="text-decoration: none; font-size: 16px;color:#2368C6;">
                    <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Edit Location</router-link>
                <router-link to="/?edit=edit" v-else style="text-decoration: none; font-size: 16px;color:#434343">
                    <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Edit Location</router-link>
            </div> -->
                    </div>

                    <div v-if="zoneId==1||zoneId==2">
                        <button v-if="lastStateColor == 2" type="button" class="btn  col-12 text-start ps-2 mt-1 rounded-4"
                            @click="isTextVisible = !isTextVisible;lastStateColor = setLastState(2)"
                            style="background-color: rgb(57 121 205);color: white;">
                            <font-awesome-icon :icon="['fas', 'sitemap']" /> Area
                        </button>
                        <button v-else type="button" class="btn btn-light col-12 text-start ps-2 mt-1 rounded-4"
                            @click="isTextVisible = !isTextVisible;lastStateColor = setLastState(2)">
                            <font-awesome-icon :icon="['fas', 'sitemap']" /> Area
                        </button>
                        <div v-if="isTextVisible ==true">
                            <div class="ps-4 col-12 mt-1">
                                <router-link @click="setLastState(2)"  to="/supperadmin/listzone" v-if="$route.fullPath=='/supperadmin/listzone'"
                                    style="text-decoration: none; font-size: 16px;color:#2368C6;">
                                    <font-awesome-icon :icon="['fas', 'border-all']" /> Area All</router-link>
                                <router-link @click="setLastState(2)"  to="/supperadmin/listzone" v-else
                                    style="text-decoration: none; font-size: 16px;color:#434343">
                                    <font-awesome-icon :icon="['fas', 'border-all']" /> Area All</router-link>
                            </div>
                            <div class="ps-4 col-12 mt-1">
                                <router-link @click="setLastState(2)"  to="/supperadmin/addzone" v-if="$route.path=='/supperadmin/addzone'"
                                    style="text-decoration: none; font-size: 16px;color:#2368C6;">
                                    <font-awesome-icon :icon="['fas', 'plus']" /> Add Area</router-link>
                                <router-link @click="setLastState(2)"  to="/supperadmin/addzone" v-else
                                    style="text-decoration: none; font-size: 16px;color:#434343">
                                    <font-awesome-icon :icon="['fas', 'plus']" /> Add Area</router-link>
                            </div>
                        </div>
                    </div>

                    <button v-if="lastStateColor == 3" type="button" class="btn  col-12 text-start ps-2 mt-1 rounded-4"
                        @click="isTextVisible2 = !isTextVisible2;lastStateColor = setLastState(3)"
                        style="background-color: rgb(57 121 205);color: white;">
                        <font-awesome-icon :icon="['fas', 'user']" /> User
                    </button>
                    <button v-else type="button" class="btn btn-light col-12 text-start ps-2 mt-1 rounded-4"
                        @click="isTextVisible2 = !isTextVisible2;lastStateColor = setLastState(3)">
                        <font-awesome-icon :icon="['fas', 'user']" /> User
                    </button>
                    <div v-if="isTextVisible2 ==true">
                        <div class="ps-4 col-12 mt-1">
                            <router-link @click="setLastState(3)"  to="/listuser" v-if="$route.fullPath=='/listuser'"
                                style="text-decoration: none; font-size: 16px;color:#2368C6;">
                                <font-awesome-icon :icon="['fas', 'user']" /> User All</router-link>
                            <router-link @click="setLastState(3)"  to="/listuser" v-else
                                style="text-decoration: none; font-size: 16px;color:#434343">
                                <font-awesome-icon :icon="['fas', 'user']" /> User All</router-link>
                        </div>
                        <div class="ps-4 col-12 mt-1">
                            <router-link @click="setLastState(3)"  to="/supperadmin/adduser" v-if="$route.path=='/supperadmin/adduser'"
                                style="text-decoration: none; font-size: 16px;color:#2368C6">
                                <font-awesome-icon :icon="['fas', 'plus']" /> Add Admin</router-link>
                            <router-link @click="setLastState(3)"  to="/supperadmin/adduser" v-else
                                style="text-decoration: none; font-size: 16px;color:#434343">
                                <font-awesome-icon :icon="['fas', 'plus']" /> Add Admin</router-link>
                        </div>
                        <!-- <div class="ps-4 col-12 mt-1">
                <router-link to="/listuser?edit=edit" v-if="$route.fullPath=='/listuser?edit=edit'"
                    style="text-decoration: none; font-size: 16px;color:#2368C6;">
                    <font-awesome-icon :icon="['fas', 'user']" /> Edit User</router-link>
                <router-link to="/listuser?edit=edit" v-else
                    style="text-decoration: none; font-size: 16px;color:#434343">
                    <font-awesome-icon :icon="['fas', 'user']" /> Edit User</router-link>
            </div> -->
                    </div>


                    <button v-if="lastStateColor == 4" type="button" class="btn  col-12 text-start ps-2 mt-1 rounded-4"
                        @click="isTextVisible4 = !isTextVisible4;lastStateColor = setLastState(4)"
                        style="background-color: rgb(57 121 205);color: white;">
                        <font-awesome-icon :icon="['fas', 'circle-info']" /> Info
                    </button>
                    <button v-else type="button" class="btn btn-light col-12 text-start ps-2 mt-1 rounded-4"
                        @click="isTextVisible4 = !isTextVisible4;lastStateColor = setLastState(4)">
                        <font-awesome-icon :icon="['fas', 'circle-info']" /> Info
                    </button>
                    <div v-if="isTextVisible4 ==true">
                        <div class="ps-4 col-12 mt-1">
                            <router-link @click="setLastState(4)"  to="/help" v-if="$route.fullPath=='/help'"
                                style="text-decoration: none; font-size: 16px;color:#2368C6;">
                                <font-awesome-icon :icon="['fas', 'question']" /> Help</router-link>
                            <router-link @click="setLastState(4)"  to="/help" v-else style="text-decoration: none; font-size: 16px;color:#434343">
                                <font-awesome-icon :icon="['fas', 'question']" /> Help</router-link>
                        </div>
                        <!-- <div class="ps-4 col-12 mt-1">
                <router-link to="/supperadmin/adduser" v-if="$route.path=='/supperadmin/adduser'"
                    style="text-decoration: none; font-size: 16px;color:#2368C6">
                    <font-awesome-icon :icon="['fas', 'plus']" /> My Info</router-link>
                <router-link to="/supperadmin/adduser" v-else
                    style="text-decoration: none; font-size: 16px;color:#434343">
                    <font-awesome-icon :icon="['fas', 'plus']" /> My Info</router-link>
            </div> -->
                    </div>
                </div>

                <button type="button" class="btn  col-12 text-start ps-2 mt-1 rounded-4" @click="logout()">
                    <font-awesome-icon :icon="['fas', 'right-from-bracket']" /> Logout
                </button>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                zoneId: null,
                side_state: null,
                role: "",
                lastStateColor: null,
                isTextVisible: true,
                isTextVisible2: true,
                locationVisible: true,
                isTextVisible4: true,
                color: '#434343'
            }
        },
        updated() {
            this.checkSize();
        },

        methods: {
            checkSize() {
                console.log("update")
                if (this.$screen.width > 765) {
                    this.side_state = true;
                }
            },
            logout() {
                localStorage.clear();
                this.$router.push({
                    path: '/login'
                });

            },
            saveState(state) {
                if (state == false) {
                    localStorage.setItem('side_state', false);
                    this.side_state = localStorage.getItem('side_state');
                    if (this.side_state === 'true') {
                        this.side_state = true;
                    } else if (this.side_state === 'false') {
                        this.side_state = false;
                    }
                } else {
                    localStorage.setItem('side_state', true);
                    this.side_state = localStorage.getItem('side_state');
                    if (this.side_state === 'true') {
                        this.side_state = true;
                    } else if (this.side_state === 'false') {
                        this.side_state = false;
                    }
                }
            },
            setLastState(last) {
                localStorage.setItem('lasts_state', last);
                var a = localStorage.getItem('lasts_state');
                this.lastStateColor = a;
                return a
            }
        },
        created() {
            this.side_state = localStorage.getItem('side_state');
            this.zoneId = localStorage.getItem('zone_id');

            if (this.side_state === 'true') {
                this.side_state = true;
            } else if (this.side_state === 'false') {
                this.side_state = false;
            }
            console.log(typeof this.side_state);
            try {
                // this.side_state = localStorage.getItem('side_state');
                var a = localStorage.getItem('lasts_state');
                this.lastStateColor = a;
                var b = localStorage.getItem('role');
                this.role = b;

            } catch (error) {
                localStorage.setItem('lasts_state', 1);
            }
            this.checkSize();
        },
        // Your JavaScript logic here
    };
</script>

<style scoped>
    /* @import '../components/style/navbar_global.css'; */
</style>
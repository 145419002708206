<template>

    <div class="row">

        <div class="col-xl-12" style="height: 100vh; background-color: #F8F9FA;">
            <div class="row justify-content-center">
                <div style='height: 110px;'></div>
                <div class="col-xl-5 col-lg-6 col-md-8 col-sm-9 col-12">
                    <div class="card px-4 py-4    border-0 rounded-4">
                        <div style='height: 20px;'></div>
                        <form>

                            <div class="h2 text-center">
                                <font-awesome-icon :icon="['fas', 'users']" /> Create User</div>
                            <div class="h3 text-center mb-2" style="color: #2368C6;font-size: 18px;font-weight: bold;">
                                FREEZER MONITOR SYSTEM
                            </div>

                            <div class="col-12 mt-4">
                                <div class="row">
                                    <span class="col-3 text-end">Invite id</span>
                                    <span class="col-8">{{userId }}</span>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="row">
                                    <span class="col-3 text-end">Invited email</span>
                                    <span class="col-8">{{email }}</span>
                                </div>
                            </div>
                            <div class="col-12 mt-4">
                                <div class="row">
                                    <span class="col-3 text-end">First name </span>
                                    <input v-model="fname" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 36px;">
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="row">
                                    <span class="col-3 text-end">Last name </span>
                                    <input v-model="lname" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 36px;">
                                </div>
                            </div>
                            <div class="col-12 mt-4">
                                <div class="row">
                                    <span class="col-3 text-end">Password </span>
                                    <input v-model="password" :type="passwordFieldType" class="col-8 rounded-4 border-1"
                                        style="height: 36px;">
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="row">
                                    <span class="col-3 text-end">Re-Password </span>
                                    <input v-model="re_password" :type="passwordFieldType" class="col-8 rounded-4 border-1"
                                        style="height: 36px;">
                                </div>
                            </div>
                            <p class="col-12 mt-2 text-end pe-5" style="font-size: 11px;" type="button" @click="togglePasswordVisibility">
                                <font-awesome-icon :icon="['fas', 'eye']" style="font-size: 16px;" /> Hide/Show Password
                            </p>
                            <div v-if="errorMessage" class="col-12 mt-2">
                                <div class="row">
                                    <div class="col-3 text-end"></div>
                                    <span class="col-8 text-danger">{{ errorMessage }}</span>
                                </div>
                            </div>
                            <!-- Submit button -->
                            <div class="text-center mt-5 mx-5"> <button type="button" data-mdb-button-init
                                    data-mdb-ripple-init class="btn btn-primary btn-block mb-4 col-12 rounded-4"
                                    @click="AddNewUser()">
                                    <font-awesome-icon :icon="['fas', 'user-plus']" /> Create My
                                    User</button></div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="successModal" aria-hidden="true" aria-labelledby="successModalLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="successModalLabel">Create Success</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">Success</div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" @click="gotologin()" data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        ref,
        onMounted
    } from 'vue';
    import {
        Modal
    } from 'bootstrap';
    import axios from 'axios'
    export default {
        name: 'App',
        components: {

        },
        setup() {
            const modalElement = ref(null);
            let modalInstance = null;

            const showModal = () => {
                if (modalInstance) {
                    modalInstance.show();
                }
            };

            onMounted(() => {
                modalElement.value = document.getElementById('successModal');
                modalInstance = new Modal(modalElement.value);
            });

            return {
                showModal
            };
        },
        data() {
            return {
                passwordFieldType: 'password',
                errorMessage: '',
                userId: '',
                email: '',
                fname: '',
                lname: '',
                password: '',
                re_password: '',

            }
        },
        watch: {
            password() {
                this.checkPassword();
            },
            re_password() {
                this.checkPasswordRe();
            }
        },
        methods: {
            togglePasswordVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            },
            checkPasswordRe() {
                if (this.password.length < 8) {
                    this.errorMessage = 'Password must be at least 8 characters.';
                } else if (this.password !== this.re_password) {
                    this.errorMessage = 'Passwords do not match.';
                } else {
                    this.errorMessage = '';
                    // Password is valid and matching, proceed with your logic here
                }
            },
            checkPassword() {
                if (this.password.length < 8) {
                    this.errorMessage = 'Password must be at least 8 characters.';
                }  else {
                    this.errorMessage = '';
                    // Password is valid and matching, proceed with your logic here
                }
            },
            gotologin() {
                this.$router.push({
                    path: '/login'
                });
            },
            async AddNewUser() {
                var load = await axios.post('https://fzm-api.sgroup.io/api/users', {
                    urID: this.userId,
                    firstName: this.fname,
                    lastName: this.lname,
                    password: this.password,
                    repeatpassword: this.re_password
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                console.log(load.data);
                if (load.status == 200) {
                    this.showModal();
                }
            },
        },
        created() {
            // this.$('#successModal').modal('show');
            this.userId = this.$route.query.userId || '';
            this.email = this.$route.query.email || '';

        }


    }
</script>

<style>
</style>
<template>
    <Navbar></Navbar>

    <div class="body container">
        <div class="row justify-content-end mt-3">
            <div class="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                <button type="button" class="btn btn-dark col-12" style="font-size: 12px;" data-bs-toggle="modal"
                    data-bs-target="#exportModal" @click="display=''">TEMPERATURE MONITORING CHART</button>
                <div class="text-center" style="font-size: 12px; ">Generate Report</div>
            </div>
        </div>
        <div class="row justify-content-start">
            <h5 class="col-xl-2  col-lg-2 col-md-2 col-sm-3 col-4 mt-2 ms-2" style="max-width: 160px;">
                Select Time:
            </h5>
            {{ val_arr }}
            <div class="col-xl-4 col-lg-5 col-md-9 col-sm-8 col-12 ">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="cc1" id="cc1_1" autocomplete="off"
                        @click="getChartOne('temp','1hr','','')" checked>
                    <label class="btn btn-outline-dark" for="cc1_1">1 HR</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_2" @click="getChartOne('temp','1d','','')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_2">1 D</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_3" @click="getChartOne('temp','1w','','')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_3">1 W</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_4" @click="getChartOne('temp','1m','','')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_4">1 M</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_5" @click="getChartOne('temp','3m','','')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_5">3 M</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_6" @click="getChartOne('temp','6m','','')"
                        autocomplete="off">
                    <label class="btn btn-outline-dark" for="cc1_6">6 M</label>

                    <input type="radio" class="btn-check" name="cc1" id="cc1_7" autocomplete="off">
                    <label class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        for="cc1_7">Date</label>

                </div>
            </div>
        </div>

        <!-- <div class="row justify-content-end pe-3">
            <div class="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                <button type="button" class="btn btn-dark" style="font-size: 14px;" data-bs-toggle="modal"
                    data-bs-target="#exportModal">TEMPERATURE MONITORING CHART</button>
                <div class="text-center" style="font-size: 12px; ">Generate Report</div>
            </div>
        </div> -->


        <div class="mt-2" id="chart">
            <apexchart type="line" height="350" :options="chartOptions" :series="series" ref="updateTempChart">
            </apexchart>
        </div>

        <hr>
        <div class="mt-2" id="chart">
            <apexchart type="line" height="350" :options="chartOptionsDoor" :series="seriesDoor" ref="updateDoorChart">
            </apexchart>
        </div>

        <div class="mt-5 col-12" id="chart" :style="{display: display}">
            <apexchart type="line" height="350" width="1300px" :options="chartOptionsR" :series="seriesR"
                ref="updateTempReportChart">
            </apexchart>
        </div>

        <div style="height: 500px;"></div>

        <!-- <div class="mt-5" id="chart">
            <apexchart type="line" height="350" :options="chartOptionsR" :series="seriesR" ref="updateTempReportChart">
            </apexchart>
        </div> -->

        <!-- <div class="h5">Ambient Temperature</div>
        <div>
            <apexchart height="280" type="area" :options="chartOptions_all_a" :series="series_all_a"></apexchart>
            <apexchart height="280" type="line" :options="options" :series="series"></apexchart>
        </div> -->
        <!-- Modal -->
        <div class=" modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-sm modal-dialog-centered ">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Select date</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- <div>{{firstdayChart}}</div>
                    <div> {{ lastdayChart }}</div> -->
                        <div class="row">
                            <form class="text-center col-6 ">

                                <label for="firstday">Date : </label>
                                <input v-model="firstdayChart" type="date" id="firstday" name="firstday">
                            </form>
                            <!-- <div class="col-1 mt-4 text-center"></div> -->
                            <form class="text-center col-6 ">
                                <label for="lastday">Date : </label>
                                <input v-model="lastdayChart" type="date" id="lastday" name="lastday">
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-dark"
                            @click="getChartOne('temp','select',firstdayChart,lastdayChart)"
                            data-bs-dismiss="modal">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class=" modal fade" id="exportModal" tabindex="-1" aria-labelledby="exportModalLabel" aria-hidden="true">
        <div class="modal-dialog" style="max-width: 340px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exportModalLabel">Export</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <div class="col-12 mt-2 ">
                        <div class="row">
                            <span class="col-4 text-end" style="font-size: 14px;">File Name (.PDF): </span>
                            <input v-model="exportModule.name" type="text" class="col-7 rounded-4  border-1"
                                style="height: 36px;">
                        </div>
                        <!-- <div class="row mt-2">
                            <span class="col-4 text-end" style="font-size: 14px;">Select Month: </span>
                            <select class="col-7 rounded-4" style="height: 36px;" id="month-select"
                                v-model="selectedMonth">
                                <option v-for="(month, index) in months" :key="index" :value="index">
                                    {{ month }}
                                </option>
                            </select>
                        </div> -->
                        <div class="row mt-2">
                            <!-- {{ selectedMonth }} -->
                            <span class="col-4 text-end" style="font-size: 14px;">Select Month: </span>
                            <select class="col-7 rounded-4" style="height: 36px;" id="month-select"
                                v-model="selectedMonth">
                                <option v-for="(month, index) in months" :key="index" :value="index">
                                    {{ month }}
                                </option>
                            </select>
                        </div>


                        <div class="row mt-2">
                            <span class="col-4 text-end" style="font-size: 14px;">Header (Option): </span>
                            <button type="button" class="btn btn-primary col-7 rounded-4"
                                @click="exportModule.headerState=true">Set Header</button>
                        </div>
                        <div class="row mt-2" v-if="exportModule.headerState==true">
                            <span class="col-4 text-end" style="font-size: 14px;">Laboratory Manager: </span>
                            <input v-model="exportModule.l_mg" type="text" class="col-7 rounded-4  border-1"
                                style="height: 36px;">
                        </div>

                        <div class="row mt-2" v-if="exportModule.headerState==true">
                            <span class="col-4 text-end" style="font-size: 14px;">Laboratory Coordinator: </span>
                            <input v-model="exportModule.l_co" type="text" class="col-7 rounded-4  border-1"
                                style="height: 36px;">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        @click="display='none'">Close</button>
                    <button v-if="selectedMonth==null" type="button" class="btn btn-secondary">Export</button>
                    <button v-else type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="generatePdf">Export</button>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
    require('jspdf-autotable');
    import jsPDF from 'jspdf';
    var time_arr = [];
    var val_arr = [];
    var val_arr_in = [];

    var doorChart = [];

    var tempRChart = [
        // [100000, null],
        // [100010, 81],
        // [100020, 80],
        // [100030, 81],
        // [100040, 80],
        // [100050, 81],
        // [100060, 80],
        // [100070, 81],
        // [100080, 80],
        // [100090, 81],
        // [100100, 80]
    ];
    var tempChart = [

        // [100000, -80],
        // [100010, -81],
        // [100020, -80],
        // [100030, -81],
        // [100040, -80],
        // [100050, -81],
        // [100060, -80],
        // [100070, -81],
        // [100080, -80],
        // [100090, -81],
        // [100100, -80]
    ];

    import ApexCharts from 'apexcharts';
    import axios from 'axios'
    import Navbar from "@/components/navbar_web.vue";
    export default {
        components: {
            Navbar
        },
        data() {
            return {
                namedeviceReport: "",
                firstdayChart: null,
                lastdayChart: null,
                display: 'none',
                reportChmax: 0,
                reportChmin: 0,
                showDropdown: false,
                selectedMonth: null,
                months: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                exportModule: {
                    headerState: false,
                    name: null,
                    header: null,
                    date: null,
                    l_mg: "",
                    l_co: ""

                },

                seriesDoor: [{
                    data: tempChart.slice(),
                    name: "Door"
                }],
                chartOptionsDoor: {
                    chart: {
                        toolbar: {
                            export: {
                                csv: {
                                    filename: "Door_chart",
                                    headerCategory: 'DateTime',
                                    // columnDelimiter: ';',
                                    dateFormatter: function (timestamp) {
                                        return timestamp.toString();
                                    },
                                }
                            },
                        },
                        id: '001',
                        height: 350,
                        type: 'line',
                        // id: 'areachart-2'
                    },

                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 2,
                        dashArray: 0,
                        // curve: 'straight'
                    },
                    grid: {
                        padding: {
                            right: 30,
                            left: 20
                        }
                    },
                    title: {
                        text: 'Door',
                        align: 'left'
                    },
                    // labels: series.monthDataSeries1.dates,
                    xaxis: {
                        type: 'datetime',
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: "dd MMM yy HH:mm:ss"
                        }
                    }
                },

                series: [{
                    data: doorChart.slice(),
                    name: "Temperature"
                }],
                chartOptions: {
                    chart: {
                        toolbar: {
                            export: {

                                // csv: {
                                //     // format: "dd MMM yy HH:mm:ss",
                                //     filename: "Temperature_chart",
                                //     columnDelimiter: ',',
                                //     headerCategory: 'Datetime',
                                //     headerValue: 'value',
                                //     categoryFormatter(x) {
                                //         return x
                                //     }
                                //     // valueFormatter(y) {
                                //     //     return y
                                //     // }
                                // },
                                csv: {
                                    filename: "Temperature_chart",
                                    headerCategory: 'DateTime',
                                    // columnDelimiter: ';',
                                    dateFormatter: function (timestamp) {
                                        return timestamp.toString();
                                    },
                                }
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 2,
                        dashArray: 0,
                        // curve: 'straight'
                    },
                    grid: {
                        padding: {
                            right: 30,
                            left: 20
                        }
                    },
                    title: {
                        text: 'Temperature',
                        align: 'left'
                    },
                    // labels: series.monthDataSeries1.dates,
                    xaxis: {
                        labels: {
                            datetimeUTC: true,
                            // datetimeFormatter: {
                            //     year: 'yyyy',
                            //     month: "MMM 'yy",
                            //     day: 'dd MMM',
                            //     hour: 'HH:mm',
                            // },
                            // format: "dd MMM yy HH:mm:ss"
                        },
                        // format: "dd MMM yy HH:mm:ss",
                        type: 'datetime',
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: "dd MMM yy HH:mm:ss"
                        }
                    }
                },
                seriesR: [{
                    data: tempRChart.slice(),
                    name: "tempreport"
                }],
                chartOptionsR: {
                    chart: {
                        id: 'ct',
                        height: 350,
                        type: 'line',
                        // id: 'areachart-2'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 2,
                        dashArray: 0,
                        // curve: 'straight'
                    },
                    grid: {
                        padding: {
                            right: 30,
                            left: 20
                        }
                    },
                    title: {
                        text: 'Temperature For Report',
                        align: 'left'
                    },
                    yaxis: {
                        tickAmount: 20,
                        // logBase: 20,
                        stepSize: 0.5,
                        forceNiceScale: true,
                        max: -67,
                        min: -73,
                        labels: {
                            show: true,
                            //   decimalsInFloat:10
                            //   align: 'right',
                            //   minWidth: 0,
                            //   maxWidth: 160,
                            //   style: {
                            //       colors: [],
                            //       fontSize: '12px',
                            //       fontFamily: 'Helvetica, Arial, sans-serif',
                            //       fontWeight: 400,
                            //       cssClass: 'apexcharts-yaxis-label',
                            //   },
                            //   offsetX: 0,
                            //   offsetY: 0,
                            //   rotate: 0,
                            //   formatter: (value) => { return val },
                        },
                    },
                    markers: {
                        size: 5
                    },
                    // labels: series.monthDataSeries1.dates,
                    xaxis: {
                        // min:10,
                        // max:100,
                        tickAmount: 'dataPoints',
                        labels: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        type: 'datetime',
                    },
                },

            }
        },
        watch: {
            selectedMonth(newMonth, oldMonth) {
                // this.selectedMonth = newMonth;
                this.getChartReportOne(newMonth + 1);
                console.log(newMonth + 1);
                this.handleMonthChange(newMonth, oldMonth);
            }
        },
        methods: {
            handleMonthChange(newMonth, oldMonth) {
                console.log(`Month changed from ${this.months[oldMonth]} to ${this.months[newMonth]}`);
                // Add your custom logic here
            },
            toggleDropdown() {
                this.showDropdown = !this.showDropdown;
            },
            onMonthChange() {
                console.log(`Selected month: ${this.selectedMonth}`);
            },
            generatePdf() {
                const chartWidth = 1920; // example high resolution width
                const chartHeight = 1080; // example high resolution height
                ApexCharts.exec('ct', 'dataURI', {
                    width: chartWidth,
                    height: chartHeight
                }).then(({
                    imgURI
                }) => {
                    const doc = new jsPDF('l', 'pt', 'a4');
                    // Define the dimensions and position for the image in the PDF
                    const imgWidth = 850; // desired width in the PDF
                    const imgHeight = 235; // maintain aspect ratio
                    const imgX = 5;
                    const imgY = 90;

                    doc.setFontSize(14);
                    doc.setFont("helvetica", "bold");
                    // Text header
                    const headerPDF = "TEMPERATURE MONITORING CHART";
                    const pageWidth = doc.internal.pageSize.getWidth();
                    // Calculate the headerPDF width
                    const headerPDFWidth = doc.getStringUnitWidth(headerPDF) * doc.internal.getFontSize() / doc
                        .internal
                        .scaleFactor;
                    // Calculate the horizontal position for the centered headerPDF
                    const x = (pageWidth - headerPDFWidth) / 2;
                    // Add the headerPDF at the calculated position
                    doc.text(headerPDF, x, 35);

                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    var p_mg;
                    if (this.exportModule.l_mg == "") {
                        p_mg = "                              "
                    } else {
                        p_mg = this.exportModule.l_mg
                    }
                    // Text header
                    const mg_name = "Laboratory Manager: " + p_mg;
                    // Calculate the mg_name width
                    const mg_nameWidth = doc.getStringUnitWidth(mg_name) * doc.internal.getFontSize() / doc
                        .internal
                        .scaleFactor;
                    // Calculate the horizontal position for the centered mg_name
                    const x2 = (pageWidth - mg_nameWidth) / 2;
                    // Add the mg_name at the calculated position
                    doc.text(mg_name, x2, 50);

                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    var p_co;
                    if (this.exportModule.l_co == "") {
                        p_co = "                              "
                    } else {
                        p_co = this.exportModule.l_co;
                    }
                    // Text header
                    const co_name = "Laboratory Coordinator: " + p_co;
                    // Calculate the co_name width
                    const co_nameWidth = doc.getStringUnitWidth(co_name) * doc.internal.getFontSize() / doc
                        .internal
                        .scaleFactor;
                    // Calculate the horizontal position for the centered co_name
                    const x3 = (pageWidth - co_nameWidth) / 2;
                    // Add the co_name at the calculated position
                    doc.text(co_name, x3, 64);


                    const date = new Date();

                    const yy = String(date.getFullYear()).slice(-2);
                    const MM = String(date.getMonth() + 1).padStart(2, '0');
                    const dd = String(date.getDate()).padStart(2, '0');

                    const hh = String(date.getHours()).padStart(2, '0');
                    const mm = String(date.getMinutes()).padStart(2, '0');
                    // const ss = String(date.getSeconds()).padStart(2, '0');

                    const formattedDate = `${dd}/${MM}/${yy} ${hh}:${mm}`;

                    // const formattedDate = `${yy}/${MM}/${dd}`;

                    // console.log(formattedDate); // Output: yy/MM/yyyy

                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    // Text header
                    const datePDF = "Date: " + formattedDate;
                    doc.text(datePDF, 20, 30);

                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    // Text header
                    const namePDF = "Name: " + localStorage.getItem('firstName') + "  " + localStorage.getItem(
                        'lastName');
                    doc.text(namePDF, 20, 45);

                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    // Text header
                    const location = "Device: " + this.namedeviceReport;
                    doc.text(location, 20, 60);

                    doc.setFontSize(8);
                    doc.setFont("helvetica", "normal");
                    doc.text("Page 1", 795, 30);


                    // Add image to PDF
                    doc.addImage(imgURI, 'PNG', imgX, imgY, imgWidth, imgHeight);

                    // Define the header
                    const header = [
                        [{
                                content: 'Date',
                                colSpan: 1,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '1',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '2',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '3',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '4',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '5',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '6',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '7',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '8',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '9',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '10',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '11',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '12',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '13',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '14',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '15',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '16',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '17',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '18',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '19',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '10',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '21',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '22',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '23',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '24',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '25',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '26',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '27',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '28',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '29',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '30',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                            {
                                content: '31',
                                colSpan: 2,
                                styles: {
                                    halign: 'center'
                                }
                            },
                        ],
                        ['', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM',
                            'PM', 'AM', 'PM',
                            'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM',
                            'PM', 'AM',
                            'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM',
                            'AM', 'PM',
                            'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM',
                            'PM', 'AM',
                            'PM',
                        ]
                    ];

                    // Define the body
                    var act = ['Actual Time'];
                    var acv = ['Actual Values'];

                    act.push(...time_arr);
                    acv.push(...val_arr);
                    console.log(val_arr)
                    const body = [
                        act,
                        acv,
                        ['INITIAL'],
                        ['Checked By'],
                        ['Deviation']
                        // ['Data 1', 'Data 2', 'Data 3', 'Data 4','Data 1', 'Data 2', 'Data 3', 'Data 4','Data 1', 'Data 2', 'Data 3', 'Data 4','Data 1', 'Data 2', 'Data 3', 'Data 4'],
                        // ['Data 5', 'Data 6', 'Data 7', 'Data 8'],
                        // ['Data 9', 'Data 10', 'Data 11', 'Data 12']
                    ];

                    // Add the table to the document
                    doc.autoTable({
                        head: header,
                        body: body,
                        // startX: 250,
                        startY: 325, // Start 20 units from the top of the page
                        margin: {
                            top: 0,
                            left: 5,
                            right: 5,
                            bottom: 0
                        }, // Small margins to maximize table width
                        tableWidth: 'auto', // Auto width for the table to fit within margins
                        columnStyles: {
                            0: {
                                cellWidth: 45,

                                // angle: 90, align: 'center', baseline: 'middle' 
                            }, // Set the width of the first column

                        },

                        // didDrawCell: function (data) {
                        //     // Check if we are in the body section and in the first row
                        //     if (data.section === 'body' && data.row.index === 0) {
                        //         var text = data.cell.raw;
                        //         var xPos = data.cell.x + data.cell.width / 1.4;
                        //         var yPos = data.cell.y + data.cell.height / 5;

                        //         // Clear the previous content in the cell
                        //         doc.setFillColor(255, 255, 255); // Set fill color to white
                        //         doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell
                        //             .height, 'F'); // Fill rectangle with white color

                        //         // Rotate and draw the new text
                        //         doc.saveGraphicsState();
                        //         doc.text(text, xPos, yPos, {
                        //             angle: -90,
                        //             align: 'center',
                        //             baseline: 'middle'
                        //         });
                        //         doc.restoreGraphicsState();
                        //     }


                        // },
                        didDrawCell: function (data) {
                            // Check if we are in the body section and in the first or second row
                            if (data.section === 'body') {
                                if (data.row.index === 0) { // First row
                                    rotateTextVertically(data, -90);
                                } else if (data.row.index === 1) { // Second row
                                    rotateTextVertically(data, -90);
                                }
                            }
                        },
                        styles: {
                            lineWidth: 0.2, // Border width for all cells
                            lineColor: [0, 0, 0], // Black border color for all cells
                            //   overflow: 'linebreak',
                            //   cellWidth: 'wrap', // Adjust cell width to wrap text
                            fontSize: 8, // Font size for the body cells
                            // border: 1,
                        },
                        theme: 'grid',
                        headStyles: {
                            padding: 0,
                            border: 1,
                            // horizontalPageBreak : false,
                            fontSize: 3.6, // Set the font size for the headers
                            fillColor: [255, 255, 255], // White background for headers
                            textColor: [0, 0, 0], // Black text color for headers
                            lineWidth: 0.2, // Border width for headers
                            lineColor: [0, 0, 0] // Black border color for headers
                        },
                    });

                    function rotateTextVertically(data, angle) {
                        var text = data.cell.raw;
                        var xPos = data.cell.x + data.cell.width / 0.9;
                        var yPos = data.cell.y + data.cell.height / 6;

                        // Clear the previous content in the cell
                        doc.setFillColor(255, 255, 255); // Set fill color to white
                        doc.rect(data.cell.x + 1, data.cell.y + 1, data.cell.width + 1, data.cell.height + 1,
                            'F'); // Fill rectangle with white color

                        // Rotate and draw the new text
                        doc.saveGraphicsState();
                        doc.text(text, xPos, yPos, {
                            angle: angle,
                            align: 'center',
                            baseline: 'middle'
                        });
                        doc.restoreGraphicsState();
                    }

                    // Add a new page
                    doc.addPage();
                    doc.setFontSize(14);
                    doc.setFont("helvetica", "bold");
                    // Text header
                    const headerPDF2 = "TEMPERATURE MONITORING LOG";
                    const pageWidth2 = doc.internal.pageSize.getWidth();
                    // Calculate the headerPDF width
                    const headerPDFWidth2 = doc.getStringUnitWidth(headerPDF) * doc.internal.getFontSize() / doc
                        .internal
                        .scaleFactor;
                    // Calculate the horizontal position for the centered headerPDF
                    const xx = (pageWidth2 - headerPDFWidth2) / 2;
                    // Add the headerPDF at the calculated position
                    doc.text(headerPDF2, xx, 35);

                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    doc.text(mg_name, x2, 50);

                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    doc.text(co_name, x3, 64);



                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    // Text header
                    doc.text(datePDF, 20, 30);

                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    // Text header
                    doc.text(namePDF, 20, 45);

                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    // Text header
                    doc.text(location, 20, 60);

                    doc.setFontSize(14);
                    doc.setFont("helvetica", "normal");
                    doc.text("Monthly Log", 20, 95);

                    doc.setFontSize(8);
                    doc.setFont("helvetica", "normal");
                    doc.text("Page 2", 795, 30);

                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    // doc.text("1 Date: 24/07/10 00:00                 Test Log First for demo 1", 20, 113);
                    // doc.text("2 Date: 24/07/10 00:00                 Test Log First for demo 2", 20, 123);
                    // doc.text("3 Date: 24/07/10 00:00                 Test Log First for demo 3", 20, 133);
                    // doc.text("4 Date: 24/07/10 00:00                 Test Log First for demo 4", 20, 143);
                    // doc.text("5 Date: 24/07/10 00:00                 Test Log First for demo 5", 20, 153);
                    // Save the PDF
                    doc.save(this.exportModule.name + '.pdf');
                    this.display = "none";
                });
            },
            async getDataDevice() {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/device', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'deviceid': this.$route.params.id
                        }
                    })
                    this.namedeviceReport = load.data.name
                    // console.log(load.data.name)

                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        // mqttHook.unSubscribe("@msg/FZMIOT001/" + this.DeviceSN)
                        // mqttHook.unRegisterEvent("@msg/FZMIOT001/" + this.DeviceSN)
                        // this.getDataDevice();
                        this.getDataDevice();
                    } else {
                        console.error(error);
                    }
                }
            },
            async getChartReportOne(montha) {
                time_arr = []
                val_arr = []
                val_arr_in = []
                var chartLoadReturn = [];
                // const datea = new Date();
                // const montha = datea.getMonth() + 1;
                // console.log(montha);
                // this.selectedMonth = montha - 1;
                try {
                    const response = await axios.get('https://fzm-api.sgroup.io/api/graph/report', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'x-requested-deviceid': this.$route.params.id,
                            // 'x-requested-deviceid': "D261578044443",
                            'month': montha,
                            'year': 2024,
                            'hour-start': 8,
                            'hour-end': 16,
                            'x-requested-name': "temp",
                        }

                    });

                    var load = response.data;
                    const year = 2024;
                    const month = montha; // July (months are zero-indexed in JS)

                    const getDaysInMonth = (year) => new Date(year, 1, 0).getDate();

                    const newData = [];
                    const daysInMonth = getDaysInMonth(year, month);
                    const times = ['08:00:00', '16:00:00'];

                    for (let day = 1; day <= daysInMonth; day++) {
                        times.forEach(time => {
                            const dateStr =
                                `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${time}`;

                            const existingData = load.find(entry => entry.time_b === dateStr);
                            if (existingData) {
                                // newData.push(existingData);
                                // chartLoadReturn.push([existingData["time_b"], existingData["temp"]]);

                                const timePart = existingData["time_b"].split(' ')[1];
                                const time = timePart.slice(0, 5);
                                if (time == "16:00") {
                                    time_arr.push("04:00");
                                    var a = existingData["time_b"];
                                    var datePart = a.split(" ")[0];
                                    console.log(datePart);
                                    chartLoadReturn.push([datePart+" 20:00:00", existingData["temp"]]);
                                } else {
                                    time_arr.push(time);
                                    chartLoadReturn.push([existingData["time_b"], existingData["temp"]]);
                                }
                                val_arr.push(existingData["temp"].toString());
                                val_arr_in.push(existingData["temp"]);

                            } else {
                                chartLoadReturn.push([dateStr, null]);

                                const timePart = dateStr.split(' ')[1];
                                const time = timePart.slice(0, 5);
                                // time_arr.push(time);
                                if (time == "16:00") {
                                    time_arr.push("04:00");
                                } else {
                                    time_arr.push(time);
                                }

                                val_arr.push("");
                                val_arr_in.push(0);
                                // newData.push({
                                //     time_b: dateStr,
                                //     deviceId,
                                //     temp: null
                                // });
                            }
                        });
                    }

                    console.log(newData);
                    console.log(chartLoadReturn);

                    this.reportChmax = Math.max(...val_arr_in);
                    this.reportChmin = Math.min(...val_arr_in);
                    console.log(this.reportChmax);
                    console.log(load)

                    this.seriesR = [{
                        data: chartLoadReturn,
                        name: "tempreport"
                    }];
                    // this.chartOptionsR.yaxis.min = this.reportChmin -20;
                    // this.chartOptionsR.yaxis.max = this.reportChmax -40;

                    if (this.$refs.updateTempReportChart) {
                        this.$refs.updateTempReportChart.updateOptions(this.chartOptionsR, false, true);
                        this.$refs.updateTempReportChart.updateSeries(this.seriesR);
                    }
                    console.log(chartLoadReturn);
                    console.log(val_arr_in)

                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getChartReportOne(montha);
                    } else {
                        console.error(error);
                    }
                }

            },
            async getChartOne(nameChart, mode, T_start, T_end) {
                var chartLoadReturn = [];
                try {
                    const response = await axios.get('https://fzm-api.sgroup.io/api/graph', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'x-requested-deviceid': this.$route.params.id,
                            'x-requested-mode': mode,
                            'x-requested-name': nameChart,
                            'timestart': T_start,
                            'timeend': T_end
                        }

                    });

                    var load = response.data;
                    for (let index = 0; index < load.length; index++) {
                        var date = new Date(load[index]["time_b"]);
                        date.setHours(date.getHours() + 7);

                        // Convert back to your desired format
                        var formattedDate =
                            `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;

                        chartLoadReturn.push([formattedDate, load[index]["temp"]])
                    }
                    this.$refs.updateTempChart.updateSeries([{
                        data: chartLoadReturn
                    }]);
                    console.log(response.data);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getChartOne(nameChart, mode, T_start, T_end);
                    } else {
                        console.error(error);
                    }
                }
            },
            async getChartOneDoor(nameChart, mode) {
                var chartLoadReturn = [];
                try {
                    const response = await axios.get('https://fzm-api.sgroup.io/api/graph', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'x-requested-deviceid': this.$route.params.id,
                            'x-requested-mode': mode,
                            'x-requested-name': nameChart,
                            'timestart': "",
                            'timeend': ""
                        }

                    });

                    var load = response.data;
                    for (let index = 0; index < load.length; index++) {
                        var date = new Date(load[index]["time_b"]);
                        date.setHours(date.getHours() + 7);

                        // Convert back to your desired format
                        var formattedDate =
                            `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;


                        var st = 0;
                        if (load[index]["door"] === false) {
                            st = 0
                        } else {
                            st = 1;
                        }
                        chartLoadReturn.push([formattedDate, st])
                    }
                    this.$refs.updateDoorChart.updateSeries([{
                        data: chartLoadReturn
                    }]);
                    console.log(response.data);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getChartOneDoor(nameChart, mode);
                    } else {
                        console.error(error);
                    }
                }
            }
        },
        created() {
            this.getDataDevice();
            this.getChartOne('temp', '1hr', '', '');
            this.getChartOneDoor('door', '1m');
            // this.getChartReportOne();
        }
    };
</script>

<style scoped>


</style>
<template>

    <body style="background-color: #F8F9FA;height: 100vh;">
        <div class="row justify-content-center">
            <div style='height: 160px;'></div>
            <div class="col-xl-4 col-lg-5 col-md-8 col-sm-11 col-11 ">
                <div class="card px-4 py-3   rounded-5 border-0 shadow-sm">
                    <div style='height: 20px;'></div>
                    <form>
                        <div class="h3 text-center mb-2" style="color: #2368C6;font-size: 20px;font-weight: bold;">
                            <font-awesome-icon :icon="['fas', 'temperature-low']" style="font-size: 26px;" /> FREEZER
                            MONITOR SYSTEM</div>
                        <div class="h5 text-center pt-2" style="color: #4D4D4D;">Log in to your account</div>
                        <div class="text-center pb-3" style="color: grey;font-size: 14px;">Welcome back! Please enter your details</div>
                        <!-- Email input -->
                        <div data-mdb-input-init class="form-outline mb-3">
                            <input v-model="username" type="email" id="form2Example1" class="form-control rounded-4" />
                            <label class="form-label" for="form2Example1" style="color: #666666;">
                                <font-awesome-icon :icon="['fas', 'envelope']" /> Email address</label>
                        </div>
                        <!-- Password input -->
                        <div data-mdb-input-init class="form-outline mb-3">
                            <input v-model="password" type="password" id="form2Example2" class="form-control rounded-4" />
                            <label class="form-label" for="form2Example2" style="color: #666666;">
                                <font-awesome-icon :icon="['fas', 'key']" /> Password</label>
                        </div>
                        <div class="mb-3 text-end" style="font-size: 12px;">
                        <span>Forgot password | Can't log in</span> <router-link to="/forgotpassword"><a href="">Reset Password</a></router-link>
                        </div>
                        <!-- Submit button -->
                        <div class="text-center"> <button type="button" data-mdb-button-init data-mdb-ripple-init
                                class="btn btn-primary btn-block mb-4 col-12 rounded-4" @click='login(username,password)'>
                                <font-awesome-icon :icon="['fas', 'right-to-bracket']" /> Login</button></div>
                    </form>
                </div>
            </div>
        </div>
    </body>

</template>

<script>
    import axios from 'axios'
    export default {
        name: 'App',
        components: {},
        data() {
            return {

                username: "",
                password: ""
            }
        },
        methods: {
            async login(username, password) {
                var load = await axios.post('https://fzm-api.sgroup.io/auth/signin', {
                    email: username,
                    password: password
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                console.log(load.data);
                localStorage.setItem('data', JSON.stringify(load.data));


                localStorage.setItem('firstName', load.data.firstName)
                localStorage.setItem('lastName', load.data.lastName)
                localStorage.setItem('email', load.data.email)
                localStorage.setItem('side_state', true)

                localStorage.setItem('user_id', load.data.id)
                localStorage.setItem('zone_id', load.data.zoneId)
                localStorage.setItem('accessToken', load.data.accessToken)
                localStorage.setItem('refreshToken', load.data.refreshToken)
                localStorage.setItem('role', load.data.role)
                if (load.data.role == "Owner") {
                    localStorage.setItem('lasts_state', 1)
                    console.log("Owner")
                    this.$router.push({
                        path: '/'
                    });
                } else if (load.data.role == "Admin") {
                    localStorage.setItem('lasts_state', 1)
                    this.$router.push({
                        path: '/'
                    });
                    console.log("Admin")
                } else if (load.data.role == "User") {
                    localStorage.setItem('lasts_state', 1)
                    this.$router.push({
                        path: '/'
                    });
                    console.log("User")
                }
            }
        }
    }
</script>

<style>
</style>
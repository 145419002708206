<template>
    <Navbar />

    <body>
        <div class="row justify-content-center">
            <Sidebar_web></Sidebar_web>
            <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 bg-white" style="min-height: 100vh;">

                <div style="height: 20px;"></div>
                <div class="row">
                    <div class="col-6">
                        <div class="h4"> </div>
                    </div>

                    <!-- <div class="col-6 text-end"><span class="h6">Add Zone
                            <router-link to="/adddevice">
                                <button class="btn   border-0 rounded-circle" type="submit"
                                    style="height: 40px;width: 40px;color: white; background-color:  #0097B2;">
                                    <h4>+</h4>
                                </button>
                            </router-link>
                        </span></div> -->
                </div>

                <div class="h5 mt-3">
                    <font-awesome-icon :icon="['fas', 'globe']" /> Area</div>
                <table class="container mt-2 border ">
                    <tr style="background-color:  #dddddd;">
                        <th style="font-weight: bold;">Name Area</th>
                        <!-- <th style="font-weight: bold;">Cover Area</th> -->
                        <th style="font-weight: bold;">Country</th>
                        <th style="font-weight: bold;">Create At</th>
                        <th class="text-center" style="font-weight: bold;">Edit/Delete</th>
                    </tr>
                    <tr class="border" v-for="(item, index) in itemZones" :key="index">
                        <td>&nbsp; {{ item.name }}</td>
                        <!-- <td>{{ item }}</td> -->
                        <td v-if='item["setting"]["country"]==2'>Lao</td>
                        <td v-else>Thailand</td>
                        <td>{{ item.updatedAt }}</td>
                        <td class="text-center" style="width: 200px;">
                            <router-link :to="'/supperadmin/addzone?edit=edit&id='+item.id"
                                style="text-decoration: none;">
                            <button type="button" class="btn btn-primary mt-1" style="width: 80px;"><span style="font-size: 12px;"
                                    >
                                    <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Edit</span>
                                </button>
                            </router-link>
                            <button type="button" class="btn btn-danger mt-1" style="width: 80px;" data-bs-toggle="modal"
                                data-bs-target="#exampleModal" @click="delete_id=item.id"><span
                                    style="font-size: 12px;">
                                    <font-awesome-icon :icon="['fas', 'trash']" /> Delete</span></button></td>
                    </tr>
                </table>
            </div>
            <!-- Modal delete -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Delete Zone</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            Confirm Delete Zone
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-danger" @click="delZoneOne(delete_id)"
                                data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editModalLabel">Edit Zone</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body ">
                            <div class="col-11 mt-2">
                                <div class="row">
                                    <span class="col-4 text-end">Name Zone </span>
                                    <input v-model="name" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 30px;">
                                </div>
                            </div>

                            <div class="col-11 mt-2">
                                <div class="row">
                                    <span class="col-4 text-end">Country</span>
                                    <input v-model="country" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 30px;">
                                </div>
                            </div>

                            <div class="col-11 mt-2">
                                <div class="row">
                                    <span class="col-4 text-end">Geography</span>
                                    <input v-model="geography" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 30px;">
                                </div>
                            </div>

                            <div class="col-11 mt-2">
                                <div class="row">
                                    <span class="col-4 text-end">Province</span>
                                    <input v-model="provinces" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 30px;">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>


</template>

<script>
    import Sidebar_web from '@/components/sidebar_web.vue'
    import axios from 'axios'
    // import Navbar from "@/components/navbar_web.vue";
    export default {
        components: {
            Sidebar_web
            // Navbar
        },
        data() {
            return {
                delete_id: null,
                selected: "ทั้งหมด",
                headers: [{
                        text: "Emai",
                        value: "email"
                    },
                    {
                        text: "Date create",
                        value: "date"
                    },
                    {
                        text: "Role",
                        value: "role"
                    },
                    {
                        text: "Edit",
                        value: "edit"
                    }
                ],
                itemZones: [],


            };
        },
        computed: {

        },
        methods: {

            async getZoneAll() {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/zone/all', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                        }
                    });
                    this.itemZones = load.data;
                    console.log('API response:', load.data); // Log the API response data
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getZoneAll();
                    } else {
                        console.error(error);
                    }
                    console.error('Error fetching data:', error); // Log any errors from the API request

                }
            },
            async delZoneOne(id_zone) {
                try {
                    var load = await axios.delete('https://fzm-api.sgroup.io/api/zone/' + id_zone, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                        }
                    });
                    console.log('API response:', load.data); // Log the API response data
                    this.getZoneAll();
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.delZoneOne();
                    } else {
                        console.error(error);
                    }
                    console.error('Error fetching data:', error); // Log any errors from the API request
                }
            }
        },
        created() {
            // Initialization logic can go here if needed
        },
        mounted() {
            this.getZoneAll();
        }



    };
</script>


<style>
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        /* width: 100%; */
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    td {

        padding: 2px;
    }



    .customize-table {
        --easy-table-header-font-size: 18px;
        --easy-table-body-row-font-size: 16px;
    }
</style>
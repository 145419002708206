<template>
  <Navbar />

  <body class="">
    <div style="height: 50px;"></div>
   <div class="px-3">
    <div class="row ">
      <div class="col-4">
        <div class="col">
          รายชื่อสถานี
        </div>
        <!-- <select v-model="selected" style="height: 30px;background-color: rgba(255, 255, 255, 0.199), 255);"
          class="rounded col-12">
          <option disabled value="">ทั้งหมด</option>
          <option>ทั้งหมด</option>
          <option>B</option>
          <option>C</option>
        </select> -->
      </div>
      <div class="col-4">
        <div class="col">
          ตั้งแต่วันที่
        </div>
        <input class="col-12" type="date" name="begin" placeholder="dd-mm-yyyy" value="" min="1997-01-01"
          max="2030-12-31" style="border: none; outline: none; text-decoration: none;">

      </div>
      <div class="col-4">
        <div class="col">
          ถึงวันที่
        </div>
        <input class="col-12" type="date" name="begin" placeholder="dd-mm-yyyy" value="" min="1997-01-01"
          max="2030-12-31" style="border: none; outline: none; text-decoration: none;">

      </div>
    </div>
   </div>
    <div class="col-12 mt-5 px-3">
      <EasyDataTable :headers="headers" :items="items"   table-class-name="customize-table"/>
    </div>
  </body>


</template>

<script>
  import Navbar from "@/components/navbar_web.vue";
  export default {
    components: {

      Navbar
    },
    data() {
      return {
        selected: "ทั้งหมด",
        headers: [
          {
            text: "สถานี",
            value: "station"

          },
          {
            text: "วันที่-เวลา",
            value: "datetime"
          },
          {
            text: "สถานะ",
            value: "status"
          }
        ],
        items: [
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },

          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
          {
            station:"TestA",
            datetime:"123456",
            status:"ON"
          },
        ]

      };
    },
    computed: {

    },
    methods: {

    }
  };
</script>


<style>
.customize-table {
  --easy-table-header-font-size: 18px;
  --easy-table-body-row-font-size: 16px;
}
</style>
<template>
    <body>
        <div class="row">
            <Sidebar_web></Sidebar_web>
            <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 bg-white" style="min-height: 100vh;">
                <div class="row justify-content-center">
                    <div style='height: 120px;'></div>
                    <div class="col-5 " style="min-width: 460px;">
                        <div class="card px-4 py-4  border-0 rounded-4">
                            <div style='height: 50px;'></div>
                            <form>

                                <div v-if="z_edit=='edit'" class="h1 text-center">Edit</div>
                                <div v-else class="h1 text-center">+ Add Area</div>

                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-4 text-end">Name Area </span>
                                        <input v-model="itemOne.name" type="text" class="col-8 rounded-4  border-1"
                                            style="height: 35px; max-width: 230px;">
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="row mt-2">
                                        <span class="col-4 text-end">Country</span>
                                        <select v-model="itemOne.setting.country" class="col-8 form-select rounded-4 "
                                            aria-label="Default select example" style="max-width: 230px;">
                                            <option value="[1]">Thailand</option>
                                            <option value="[2]">Lao</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12" v-if="itemOne.setting.country=='[1]'">
                                    <div class="row mt-2">
                                        <span class="col-4 text-end">Option Zone</span>
                                        <select v-model="op_Zone" class="col-8 form-select rounded-4 "
                                            aria-label="Default select example" style="max-width: 230px;">
                                            <option value="Geography">Geography</option>
                                            <option value="Province">Province</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12" v-if="itemOne.setting.country=='[1]'&&op_Zone == 'Geography'">
                                    <div class="row mt-2">
                                        <span class="col-4 text-end">Geography</span>
                                        <select v-model="geography" class="col-8 form-select rounded-4 "
                                            aria-label="Default select example" style="max-width: 230px;">
                                            <option value="1">Northern</option>
                                            <option value="2">Central</option>
                                            <option value="3">Northeastern</option>
                                            <option value="4">Western</option>
                                            <option value="5">Eastern</option>
                                            <option value="6">Southern</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 mt-4" v-if="itemOne.setting.country=='[1]'&&op_Zone == 'Province'">
                                    <div class="row mt-2">
                                        <span class="col-4 text-end">Add Province</span>
                                        <select v-model="provinceOne" class="col-5 form-select rounded-4 "
                                            aria-label="Default select example" style="max-width: 170px;">
                                            <option v-for="(item, index) in listProvince" :key="index"
                                                :value="{ id: item.id, name: item.name_th }">
                                                {{item.name_th}}</option>
                                        </select>
                                        <button type="button" class="btn btn-dark col-3 ms-1 rounded-5"
                                            style="max-width: 50px;" @click="addProvinceArr(provinceOne)">+</button>
                                    </div>

                                    <div class="col-12 ">
                                        Selected Provinces
                                        <div class="row mt-1">
                                            <div class="col-12  border rounded-4" style="min-height: 100px;">
                                                <div class="row px-2">
                                                    <div class="col-6 border rounded-5 text-center py-1 mt-1"
                                                        style="font-size: 13px;"
                                                        v-for="(item, index) in itemOne.provincesall" :key="index">
                                                        {{ item.name }}
                                                        <font-awesome-icon :icon="['fas', 'xmark']"
                                                            style="font-size: 16px;" type="button"
                                                            @click="removeProvince(index)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Submit button -->
                                <div v-if="z_edit=='edit'" class="text-center mt-5 mx-3"> <button type="button"
                                        data-mdb-button-init data-mdb-ripple-init
                                        class="btn btn-primary btn-block mb-4 col-12 rounded-4" @click="editZone()">
                                        <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Edit
                                        Area</button></div>
                                <div v-else class="text-center mt-5 mx-3"> <button type="button" data-mdb-button-init
                                        data-mdb-ripple-init class="btn btn-primary btn-block mb-4 col-12 rounded-4"
                                        @click="AddNewZone()">
                                        + Add
                                        Area</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>

</template>

<script>
    import Sidebar_web from '@/components/sidebar_web.vue'
    import axios from 'axios'
    export default {
        name: 'App',
        components: {
            Sidebar_web,
        },
        data() {
            return {
                z_id: null,
                z_edit: null,
                op_Zone: null,
                itemOne: {
                    name: null,
                    setting: {
                        country: [],
                        allzone: false,
                        geography: [],
                        provinces: []
                    },
                    provincesall: [],
                },
                provinceOne: null,
                listProvince: null,
                name: '',
                geography: [],
                country: '',
                items: []
            }
        },
        methods: {
            async getDataZoneFormID(id) {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/zone', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'zoneid': id
                        }
                    })
                    this.itemOne = load.data;
                    if (load.data.setting.geography.toString() == "") {
                        this.op_Zone = 'Province';
                    }
                    this.itemOne.setting.country = "[" + load.data.setting.country + "]";
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getDataZoneFormID(id);
                    } else {
                        console.error(error);
                    }
                }

            },
            addProvinceArr(arr) {
                this.itemOne.provincesall.push(arr);
                this.itemOne.setting.provinces.push(arr.id);
            },
            removeProvince(index) {
                this.itemOne.provincesall.splice(index, 1);
                this.itemOne.setting.provinces.splice(index, 1);
            },
            async getDataProvince() {
                try {
                    var load = await axios.get(
                        'https://fzm-api.sgroup.io/api/thai-province-data/all/provinces', {
                            headers: {
                                'Content-Type': 'application/json',
                                'token': localStorage.getItem('accessToken')
                            }
                        })
                    this.listProvince = load.data;
                    console.log(load.data);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getDataProvince();
                    } else {
                        console.error(error);
                    }
                }
            },

            async AddNewZone() {
                var pre_load = {
                        "name": this.itemOne.name,
                        "setting": {
                            "country": JSON.parse(this.itemOne.setting.country), // id country 1TH
                            "allzone": false,
                            "geography": this.itemOne.setting
                                .geography, //ถ้าต้องการเลือกทั้งภาคให้ใส่ id  northern = 1 , central=2 , northeastern = 3 ,western = 4,eastern = 5,southern = 6
                            "provinces": this.itemOne.setting.provinces //id จังหวัด
                        }
                    }
                try {
               
                    var load = await axios.post('https://fzm-api.sgroup.io/api/zone', pre_load, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    this.$router.push({
                        path: '/supperadmin/listzone'
                    });
                    console.log(load.data);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.AddNewZone();
                    } else {
                        console.error(error);
                    }
                }
            },
            async editZone() {
                var pre_load = {
                        "id": this.z_id,
                        "name": this.itemOne.name,
                        "setting": {
                            "country": JSON.parse(this.itemOne.setting.country), // id country 1TH
                            "allzone": false,
                            "geography": this.itemOne.setting
                                .geography, //ถ้าต้องการเลือกทั้งภาคให้ใส่ id  northern = 1 , central=2 , northeastern = 3 ,western = 4,eastern = 5,southern = 6
                            "provinces": this.itemOne.setting.provinces //id จังหวัด
                        }
                    }
                try {
                
                    var load = await axios.put('https://fzm-api.sgroup.io/api/zone', pre_load, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                    this.$router.push({
                        path: '/supperadmin/listzone'
                    });
                    console.log(load.data);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.editZone();
                    } else {
                        console.error(error);
                    }
                }
            },
        },
        created() {
            this.z_id = this.$route.query.id || '';
            this.z_edit = this.$route.query.edit || '';
            if (this.z_edit == 'edit') {
                this.getDataZoneFormID(this.z_id);
            }
            this.getDataProvince();
        }
    }
</script>

<style>
</style>
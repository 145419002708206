<template>



  <body>
    <div class="row">
      <Sidebar_web></Sidebar_web>
      <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 bg-white" style="min-height: 100vh;">
        <div style="height: 20px;"></div>
        <!-- <div class="h6">Your user: tonnon.tn@gmail.com</div>
        <div class="h6">Role: Owner</div> -->
        <div class="h5 mt-3">
          <font-awesome-icon :icon="['fas', 'users']" /> List Users</div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style="font-size: 12px;">
        <table class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 ">
          <tr style="background-color:  #dddddd;">
            <th style="font-weight: bold;">
              <font-awesome-icon :icon="['fas', 'envelope']" /> Email</th>
            <!-- <th style="font-weight: bold;">Zone</th> -->
            <th style="font-weight: bold;">First Name</th>
            <th style="font-weight: bold;">Last Name</th>
            <!-- <th style="font-weight: bold;"> <font-awesome-icon :icon="['fas', 'user']" /> Role</th> -->
            <th style="font-weight: bold;" v-if="$screen.width>765">
              <font-awesome-icon :icon="['fas', 'user']" /> Role</th>
            <th style="font-weight: bold;"  v-if="$screen.width>765">
              <font-awesome-icon :icon="['fas', 'clock']" /> Create At</th>
            <th class="text-center" style="font-weight: bold;">
              <font-awesome-icon :icon="['fas', 'trash']" /> Delete</th>
          </tr>
          <tr class="border" v-for="(item, index) in itemUsers" :key="index">
            <td>{{ item.email }}</td>
            <!-- <td>{{ item.zondId }}</td> -->
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td  v-if="$screen.width>765">{{ item.role }}</td>
            <td v-if="$screen.width>765">{{ item.updatedAt }}</td>
            <td class="text-center" >
              <!-- <button type="button" class="btn btn-primary"><span style="font-size: 12px;">Edit</span></button> -->
              <button v-if="item.role!='Owner'" type="button" class="btn btn-danger ms-1" data-bs-toggle="modal" data-bs-target="#deleteUserModal"
                @click="u_delete_id=item.id,u_delete_email=item.email"><span
                  style="font-size: 12px;">Delete</span></button>
             <div v-else style="height: 35px;"></div>
            </td>
          </tr>
        </table>
      </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="deleteUserModal" tabindex="-1" aria-labelledby="deleteUserModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteUserModalLabel">Delete User</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
              Confirm Delete User
              <div>
                <font-awesome-icon :icon="['fas', 'envelope']" /> Email {{ u_delete_email }}</div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                @click="delUserOne(u_delete_id)">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>


</template>

<script>
  import Sidebar_web from '@/components/sidebar_web.vue'
  import axios from 'axios'
  // import Navbar from "@/components/navbar_web.vue";
  export default {
    components: {
      Sidebar_web
      // Navbar
    },
    data() {
      return {
        u_delete_id: '',
        u_delete_email: '',
        selected: "ทั้งหมด",
        itemUsers: [],

      };
    },
    computed: {

    },
    methods: {
      async getUsersAll() {
        try {
          var load = await axios.get('https://fzm-api.sgroup.io/api/users', {
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('accessToken')
            }
          })
          this.itemUsers = load.data;
          console.log(load.data);
        } catch (error) {
          console.error('Error fetching data:', error);
          if (error.response && error.response.status === 401) {
            console.log("error.response.status");
            this.$globalFunctions.newAccToken();
            this.getUsersAll();
          } else {
            console.error(error);
          }
        }

        // localStorage.setItem('data', JSON.stringify(load.data));
      },
      async delUserOne(id_user) {
        try {
          var load = await axios.delete('https://fzm-api.sgroup.io/api/users/' + id_user, {
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('accessToken')
            }
          });
          console.log('API response:', load.data);
          this.getUsersAll();
        } catch (error) {
          console.error('Error fetching data:', error);
          if (error.response && error.response.status === 401) {
            console.log("error.response.status");
            this.$globalFunctions.newAccToken();
            this.delUserOne(id_user);
          } else {
            console.error(error);
          }
        }
      }
    },
    created() {
      this.getUsersAll();
    }
  };
</script>


<style>
  .customize-table {
    --easy-table-header-font-size: 18px;
    --easy-table-body-row-font-size: 16px;
  }
</style>
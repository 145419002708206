<template>

    <body>
        <div class="row">
            <Sidebar_web></Sidebar_web>
            <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 bg-white border" style="min-height: 100vh;">
                <div class="row justify-content-center">
                    <div style='height: 120px;'></div>
                    <div class="col-5" style="min-width: 470px;">
                        <div class="card px-3 py-4  border-0 rounded-4">
                            <div style='height: 20px;'></div>
                            <form>

                                <div class="h3 text-center">+ Invite Admin User</div>
                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-4 text-end">Role</span>
                                        <div class="col-6">Admin</div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <span class="col-4 text-end">Zone</span>
                                    <select v-if="my_zone_id==1||my_zone_id==2" v-model="zoneId"
                                        class="col-6 form-select rounded-4 " aria-label="Default select example"
                                        style="max-width: 215px;">
                                        <option v-for="(item, index) in itemZones" :key="index" :value="item.id">
                                            {{item.name}}</option>
                                    </select>
                                    <span class="col-6 " v-else><span v-if="itemZones!=null">
                                            {{ getNameById(my_zone_id) }}</span></span>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class="row">
                                        <span class="col-4 text-end">Email </span>
                                        <input v-model="email" type="text" class="col-6 rounded-4  border-1"
                                            style="height: 35px;">
                                    </div>
                                </div>

                                <!-- Submit button -->
                                <div class="text-center mt-5"> <button type="button" data-mdb-button-init
                                        data-mdb-ripple-init class="btn btn-primary btn-block mb-4 col-12 rounded-4"
                                        @click="AddNewUser(email,zoneId,'Admin')">
                                        + Add
                                        User</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="successModal" aria-hidden="true" aria-labelledby="successModalLabel"
                tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="successModalLabel">Invite User Success</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="text-center">Success</div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" data-bs-dismiss="modal" @click="gotoUserList()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>

</template>

<script>
    import axios from 'axios'
    import Sidebar_web from '@/components/sidebar_web.vue'
    // import Sidebar_web from '../sidebar_web.vue'
    import {
        ref,
        onMounted
    } from 'vue';
    import {
        Modal
    } from 'bootstrap';


    export default {
        name: 'App',
        components: {
            Sidebar_web
        },
        setup() {
            const modalElement = ref(null);
            let modalInstance = null;

            const showModal = () => {
                if (modalInstance) {
                    modalInstance.show();
                }
            };

            onMounted(() => {
                modalElement.value = document.getElementById('successModal');
                modalInstance = new Modal(modalElement.value);
            });

            return {
                showModal
            };
        },
        data() {
            return {
                my_zone_id: null,
                itemZones: null,
                email: '',
                zoneId: '',
                role: '',
            }
        },
        methods: {
            getNameById(id) {
                const item = this.itemZones.find(item => item.id == id);
                this.zoneId = this.my_zone_id;
                console.log("aaaa")
                return item ? item.name : 'Not found';
            },
            async AddNewUser(email, zoneId, role) {
                try {
                    var load = await axios.post('https://fzm-api.sgroup.io/api/users/register/', {
                        email: email,
                        role: role,
                        zone: zoneId
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        }
                    })
                  
                    console.log(load.data);
                    console.log(zoneId);
                    this.showModal();
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.AddNewUser(email, zoneId, role);
                    } else {
                        console.error(error);
                    }
                }
            },

            gotoUserList() {
                this.$router.push({
                    path: '/listuser'
                });
            },

            async getXoneAll() {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/zone/all', {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('accessToken')
                    }
                })
                this.itemZones = load.data;
                console.log(load.data);   
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getXoneAll();
                    } else {
                        console.error(error);
                    }
                }
            }
        },
        created() {
            this.my_zone_id = localStorage.getItem('zone_id')
            this.getXoneAll();
            // this.callLocalVar();
        }


    }
</script>

<style>
</style>
<template>
  <nav class="navbar navbar-expand-lg " style="background-color: #F8F9FA;">
    <div class="container-fluid  container">
      <img class="rounded-4" src="https://richmond-craft-production.s3.amazonaws.com/content/stores/logos/fridge-freezer-logo.jpg" alt=""
        width="60">
        <router-link to="/" style="text-decoration: none;"> <a class="navbar-brand ms-2" href="#" style="font-size: 16px;font-weight: bold;color: #3B3B3B;">Monitoring Dashboard</a></router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">

        <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-if="$route.path=='/' || $route.path=='/map'">
          <router-link to="/" style="text-decoration: none;">
            <div v-if="$route.path=='/'" class="text-center align-middle   rounded-4 "
              style="width: 150px;height: 45px;font-size: 16px;padding-top: 10px;color: white;background-color: #0097B2;">
              Location List</div>
            <div v-else class="text-center align-middle   rounded-4 "
              style="width: 150px;height: 45px;font-size: 16px;padding-top: 10px;color: black;">
              Location List</div>
          </router-link>
          <router-link to="/map" style="text-decoration: none;">
            <div v-if="$route.path=='/map'" class="text-center align-middle   rounded-4 "
              style="width: 150px;height: 45px;font-size: 16px;padding-top: 10px;color: white;background-color: #0097B2;">
              Location On MAP</div>
            <div v-else class="text-center align-middle   rounded-4 "
              style="width: 150px;height: 45px;font-size: 16px;padding-top: 10px;color: black;">
              Location On MAP</div>
          </router-link>
        </ul>

        <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-else-if="$route.path=='/inside/device/'+$route.params.id ||$route.path=='/inside/chart/'+$route.params.id">


          <!-- <router-link to="/inside/device" style="text-decoration: none;">
            <div v-if="$route.path=='/inside/device'" class="text-center align-middle   rounded-4 "
              style="width: 120px;height: 45px;font-size: 16px;padding-top: 10px;color: white;background-color: #0097B2;">
              Device</div>
            <div v-else class="text-center align-middle   rounded-4 "
              style="width: 120px;height: 45px;font-size: 16px;padding-top: 10px;color: black;">
              Device</div>
          </router-link>

          <router-link to="/inside/chart" style="text-decoration: none;">
            <div v-if="$route.path=='/inside/chart'" class="text-center align-middle   rounded-4 "
              style="width: 120px;height: 45px;font-size: 16px;padding-top: 10px;color: white;background-color: #0097B2;">
              Chart</div>
            <div v-else class="text-center align-middle   rounded-4 "
              style="width: 120px;height: 45px;font-size: 16px;padding-top: 10px;color: black;">
              Chart</div>
          </router-link> -->
          
           <router-link  :to="'/inside/device/'+$route.params.id" style="text-decoration: none;">
            <div v-if="$route.path=='/inside/device/'+$route.params.id"  class="text-center align-middle   rounded-4 "
              style="width: 100px;height: 45px;font-size: 16px;padding-top: 10px;color: #2368C6;font-weight: bold;">
              <font-awesome-icon :icon="['fas', 'rotate-right']" /> Realtime</div>
            <div v-else class="text-center align-middle   rounded-4 "
              style="width: 100px;height: 45px;font-size: 16px;padding-top: 10px;color: black;">
              <font-awesome-icon :icon="['fas', 'rotate-right']" /> Realtime</div>
            
          </router-link>
          <router-link  :to="'/inside/chart/'+$route.params.id" style="text-decoration: none;">
            <div v-if="$route.path=='/inside/chart/'+$route.params.id" class="text-center align-middle   rounded-4 "
              style="width: 100px;height: 45px;font-size: 16px;padding-top: 10px;color: #2368C6;font-weight: bold;">
              <font-awesome-icon :icon="['fas', 'chart-simple']" />  Chart</div>
              <div v-else class="text-center align-middle   rounded-4 "
              style="width: 100px;height: 45px;font-size: 16px;padding-top: 10px;color: black;">
              <font-awesome-icon :icon="['fas', 'chart-simple']" />  Chart</div>
          </router-link>
        </ul>

        <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-else>
          <div @click="goBack" style="text-decoration: none; cursor: pointer;">
    <div class="text-center align-middle rounded-4"
         style="width: 120px; height: 45px; font-size: 16px; padding-top: 10px; color: black; font-weight: bold;">
      <font-awesome-icon :icon="['fas', 'backward']" /> Back Page
    </div>
  </div>
          <!-- <router-link  :to="'/inside/device/'+$route.params.id" style="text-decoration: none;">
            <div v-if="$route.path=='/inside/device/'+$route.params.id"  class="text-center align-middle   rounded-4 "
              style="width: 90px;height: 45px;font-size: 16px;padding-top: 10px;color: #2368C6;font-weight: bold;">
              Home</div>
            <div v-else class="text-center align-middle   rounded-4 "
              style="width: 90px;height: 45px;font-size: 16px;padding-top: 10px;color: black;">
              Home</div>
            
          </router-link>
          <router-link  :to="'/inside/chart/'+$route.params.id" style="text-decoration: none;">
            <div v-if="$route.path=='/inside/chart/'+$route.params.id" class="text-center align-middle   rounded-4 "
              style="width: 90px;height: 45px;font-size: 16px;padding-top: 10px;color: #2368C6;font-weight: bold;">
              Chart</div>
              <div v-else class="text-center align-middle   rounded-4 "
              style="width: 90px;height: 45px;font-size: 16px;padding-top: 10px;color: black;">
              Chart</div>
          </router-link> -->

         
        </ul>

        <!-- <form class="d-flex">
          <button class="btn ms-3 " type="submit">Logout</button>
        </form> -->
      </div>
    </div>
  </nav>

</template>

<script>
  export default {
    methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
    // Your JavaScript logic here
  };
</script>

<style scoped>
  @import '../components/style/navbar_global.css';

  /* Your CSS styling here */
  .btn {
    background-image: linear-gradient(195deg, #d82f2f 0%, #f95656 100%);
    color: white;
  }
</style>
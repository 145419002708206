<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid ">
            <div class="col-12 text-end ">
                <div style="height: 10px;"></div>
                <!-- <div class="" style="font-size: 16px;font-weight: bold;">Welcome to Freezer Monitor </div> -->
                <div class="row justify-content-end mt-1">
                  <div class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-7  text-end" style="max-width: 240px;">
                   <div class="row">
                    <div class="col-3 text-end" style="width: 40px;">
                        <font-awesome-icon class=" " :icon="['fas', 'circle-user']" style="font-size: 35px; " />
                    </div>
                    <div class=" col-6 text-start " style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;  ">
                        <div style="font-size: 14px;">{{ fname }} {{ lname }}</div>
                        <div style="font-size: 10px;">Role:{{ role }}</div>
                    </div>
                    <span class="col-2 text-center" style="width: 65px;">
                        <font-awesome-icon :icon="['fas', 'pen-to-square']" style="font-size: 19px;" @click="getdataUser()" data-bs-toggle="modal"
                            data-bs-target="#exampleModal" type="button" />
                        <div style="font-size: 10px;">Edit Info</div>
                    </span>
                   </div>
                  </div>
                </div>
            </div>
        </div>
    </nav>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <font-awesome-icon :icon="['fas', 'user']" /> My Profile</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-12 mt-2">
                        <div class="row">
                            <span class="col-3 text-end">
                                <font-awesome-icon :icon="['fas', 'envelope']" /> Email:</span>
                            <span class="col-8">{{email}}</span>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="row">
                            <span class="col-3 text-end">First Name: </span>
                            <input v-model="fname" type="text" class="col-8 rounded-4  border-1" style="height: 36px;">
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="row">
                            <span class="col-3 text-end">Last Name:</span>
                            <input v-model="lname" type="text" class="col-8 rounded-4  border-1" style="height: 36px;">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="editUser()" data-bs-dismiss="modal">Save Changes</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                email: '',
                fname: '',
                lname: '',
                role: '',
                user_id: ''

            }
        },
        methods: {
            async editUser() {
                var load = await axios.put('https://fzm-api.sgroup.io/api/users/', {
                    id: this.user_id,
                    firstName: this.fname,
                    lastName: this.lname
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('accessToken')
                    }
                })
                if (load.status == 200) {
                    localStorage.setItem('firstName', this.fname);
                    localStorage.setItem('lastName', this.lname);
                }

            
            },
            goBack() {
                this.$router.go(-1);
            },
            getdataUser() {
                var fn = localStorage.getItem('firstName');
                var ln = localStorage.getItem('lastName');
                var em = localStorage.getItem('email');
                var ro = localStorage.getItem('role');
                var uid = localStorage.getItem('user_id')
                this.email = em;
                this.fname = fn;
                this.lname = ln
                this.role = ro;
                this.user_id = uid;
            }
        },
        created() {
            this.getdataUser();
            // // this.$('#successModal').modal('show');
            // this.userId = this.$route.query.userId || '';
            // this.email = this.$route.query.email || '';

        }
    };
</script>

<style scoped>
    @import '../components/style/navbar_global.css';
    /* Your CSS styling here */
    /* .btn {
      background-image: linear-gradient(195deg, #d82f2f 0%, #f95656 100%);
      color: white;
    } */
    /* .btn-2 {
      background-image: linear-gradient(195deg, #d82f2f 0%, #f95656 100%);
      color: white;
    } */
</style>
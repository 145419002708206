<template>
  <!-- <Navbar style="z-index: 3" /> -->

  <body>
    <div class="row">
      <Sidebar_web></Sidebar_web>
      <span class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12" v-if="doneLoad" style="background-color: #F8F9FA;">
        <div style="height:100vh;">
          <l-map ref="map" v-model:zoom="zoom" :center="centerPoint" style="position: absolute; z-index: 0;">
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
              name="OpenStreetMap">
            </l-tile-layer>
            <l-marker v-for="(item, index) in items" :key="index" :lat-lng="item.latLng">
              <l-icon class-name="someExtraClass">
                <img class="myDIV" type="button" @click="console.log(1234)" src="../assets/pictures/freezer_icon.png"
                  :style="{ width: iconSize }">
                <!-- <div class="" style="margin-top: -100px;margin-left: -8px; font-size: 18px;">🟢</div> -->
                <!-- {{  itemsforId[index]["id"]  }} -->
                <div class="hide border rounded-3" style="font-size: 20px;">
                  <div class="text-center" style="font-size: 14px;">Location Name</div>
                  <div class="text-center" style="font-size: 16px;">{{ item.name }}</div>
                </div>
              </l-icon>
              <l-popup closeButton="false">
                <div class="col">

                  <a :href="`/inside/location/${  itemsforId[index]['id'] }`"
                    style="text-decoration: none;color: black;">
                    <div type="button" class="rounded-5  p-1 shadow" style="background-color: #eeeeeec9;">
                      <button type="button" class="btn  p-1 rounded-5 btn-lg " style="background-color: #1a94f869;">
                        <font-awesome-icon :icon="['fas', 'house']" /></button>
                      <span style="font-size: 14px;font-weight: 700;"> Go to Devic List</span>
                    </div>
                  </a>
                </div>
              </l-popup>
            </l-marker>
          </l-map>
        </div>
      </span>
    </div>
  </body>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered  modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            <font-awesome-icon :icon="['fas', 'chart-simple']" /> กราฟแสดงผล</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Chart_Consumption />
          <Log_Consumption />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Sidebar_web from '@/components/sidebar_web.vue'
  import "leaflet/dist/leaflet.css";
  import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  } from "@vue-leaflet/vue-leaflet";

  export default {
    components: {
      Sidebar_web,
      LMap,
      LTileLayer,
      LMarker,
      LIcon,
      LPopup
    },
    data() {
      return {
        doneLoad: false,
        items: [],
        itemsforId: [],
        zoom: 9,
        centerPoint:[14.348360, 100.576271],
        markerLatLng: [14.348360, 100.576271],
        iconSize: "30px",
      };
    },
    computed: {

      dynamicSize() {
        return [this.iconSize, this.iconSize * 1.15];
      },
      dynamicAnchor() {
        return [this.iconSize / 2, this.iconSize * 1.15];
      }
    },
    methods: {
      async getProjectAll() {
        try {
          const response = await axios.get('https://fzm-api.sgroup.io/api/project/all', {
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('accessToken')
            }
          });
          this.items = response.data
            .filter(project => project.Latitude && project.Longitude) // Ensure valid coordinates
            .map(project => ({
              latLng: [project.Latitude, project.Longitude],
              icon: "../assets/logo.png",
              name: project.name || " " // Assuming name might be empty and defaulting to " "
            }));
          this.itemsforId = response.data;

          var latitudes = this.items.map(function (location) {
            return location['latLng'][0];
          });

          var longitudes = this.items.map(function (location) {
            return location['latLng'][1];
          });

          var sumLat = latitudes.reduce(function (sum, value) {
            return sum + value;
          }, 0);

          var sumLng = longitudes.reduce(function (sum, value) {
            return sum + value;
          }, 0);

          var centerLat = sumLat / latitudes.length;
          var centerLng = sumLng / longitudes.length;


          console.log('Center Latitude:', centerLat);
          console.log('Center Longitude:', centerLng);
          this.centerPoint = [centerLat,centerLng];
          console.log("ssss",this.items)
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log("error.response.status");
            this.$globalFunctions.newAccToken();
            this.getProjectAll();
          } else {
            console.error(error);
          }
          console.error("Error fetching project data:", error);
        }
        console.log(this.items)
      }
    },
    async mounted() {

      await this.getProjectAll();
      this.doneLoad = true;
    }
  };
</script>

<style>
  @import '../components/style/hover.css';
  @import '../components/style/round_bt.css';

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    padding-left: 80px;
    margin-bottom: -20px;
    background-color: #00000000;
    box-shadow: none !important;
  }

  .someExtraClass {
    padding: 10px;
  }

  .red {
    /* background-color: red; */
    position: absolute;
  }

  .box {
    width: 100px;
    height: 100px;
    color: white;
    text-align: center;
  }

  .modal-xl {
    min-width: 90dvw;
  }
</style>
<template>
    <Navbar></Navbar>

    <body>
        <div class=" col-12 " style="height: 100vh; background-color: #F8F9FA;">
            <div class="row justify-content-center">
                <div style='height: 120px;'></div>
                <div class="col-4" style="min-width: 450px;">
                    <div class="card px-4 py-4  border-0 rounded-4">
                        <div style='height: 20px;'></div>
                        <form>

                            <div v-if="d_edit=='edit'" class="h1 text-center"> Edit Device</div>
                            <div v-else class="h1 text-center">+ Add Device</div>

                            <div class="col-12 mt-2">
                                <div class="row">
                                    <span class="col-3 text-end">Type</span>

                                    <select v-model="itemOne.Category" class="col-8 form-select rounded-4 "
                                        aria-label="Default select example" style="max-width: 290px;">
                                        <option selected>Select Device Type</option>
                                        <option value="Freezer">Freezer</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-12 mt-2">
                                <div class="row">
                                    <span class="col-3 text-end">Name </span>
                                    <input v-model="itemOne.name" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 30px;">
                                </div>
                            </div>

                            <div class="col-12 mt-2">
                                <div class="row">
                                    <span class="col-3 text-end">Description</span>
                                    <input v-model="itemOne.Description" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 30px;">
                                </div>
                            </div>

                            <div class="col-12 mt-2">
                                <div class="row">
                                    <span class="col-3 text-end">Serial Number</span>
                                    <input v-model="itemOne.SN" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 30px;">
                                </div>
                            </div>

                            <div class="col-12 mt-2">
                                <div class="row">
                                    <span class="col-3 text-end">Model</span>
                                    <input v-model="itemOne.Model" type="text" class="col-8 rounded-4  border-1"
                                        style="height: 30px;">
                                </div>
                            </div>
                            <!-- Submit button -->
                            <div v-if="d_edit=='edit'" class="text-center mt-5"> <button type="button"
                                    data-mdb-button-init data-mdb-ripple-init
                                    class="btn btn-primary btn-block mb-4 col-12 rounded-4" @click="editDevice()">
                                    <font-awesome-icon :icon="['fas', 'pen-to-square']" /> Edit
                                    Device</button></div>
                            <div v-else class="text-center mt-5"> <button type="button"
                                    data-mdb-button-init data-mdb-ripple-init
                                    class="btn btn-primary btn-block mb-4 col-12 rounded-4" @click="AddNewDevice()">
                                    + Add
                                    Device</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </body>

</template>

<script>
    import axios from 'axios'
    import Navbar from "@/components/navbar_web.vue";
    export default {
        name: 'App',
        components: {
            Navbar
        },
        data() {
            return {
                d_edit: null,
                d_id: null,
                itemOne: [],
                items: []
            }
        },
        methods: {
            async getDataDeviceFormID(id_d) {
             try {
                var load = await axios.get('https://fzm-api.sgroup.io/api/device', {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('accessToken'),
                        'deviceid': id_d
                    }
                })
                this.itemOne = load.data;
                console.log(load.data);
             } catch (error) {
                console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.getDataDeviceFormID(id_d);
                    } else {
                        console.error(error);
                    }
             }
            },
            async AddNewDevice() {
                try {
                    var load = await axios.post('https://fzm-api.sgroup.io/api/device/add', {
                    name: this.itemOne.name,
                    Description: this.itemOne.Description,
                    Category: this.itemOne.Category, // ชนิด
                    SN: this.itemOne.SN,
                    Model: this.itemOne.Model,
                    TempSetting: [],
                    DoorSetting: [],
                    status: false,
                    projectId: this.d_id
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('accessToken')
                    }
                })
                console.log(load.data);
                this.$router.go(-1);
            
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.AddNewDevice();
                    } else {
                        console.error(error);
                    }
                }
              
            },
            async editDevice() {
            try {
                var load = await axios.put('https://fzm-api.sgroup.io/api/device', {
                    id:this.d_id,
                    name: this.itemOne.name,
                    Description: this.itemOne.Description,
                    Category: this.itemOne.Category, // ชนิด
                    SN: this.itemOne.SN,
                    Model: this.itemOne.Model,
                    TempSetting: [],
                    DoorSetting: [],
                    status: false,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('accessToken')
                    }
                })
                console.log(load.data)   
                this.$router.go(-1);
              
            } catch (error) {
                console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.editDevice();
                    } else {
                        console.error(error);
                    }
            }
            },
        },
        created() {
            this.d_id = this.$route.query.did || '';
            this.d_edit = this.$route.query.edit || '';
            if (this.d_edit == 'edit') {
                this.getDataDeviceFormID(this.d_id);
            } else {
                this.itemOne = []
            }
        }
    }
</script>

<style>
</style>
<template>

    <Navbar></Navbar>

    <body style="background-color: white;height: 100vh;">
        <div style="height: 20px;"></div>
        <div class="col-xl-12  container">
            <!-- {{ items1 }} -->
            <div class="h6 ">Name: {{ items1.name }}</div>
            <div>Location: {{ adds}}</div>
            <div>Description: {{ items1.Description }}</div>
            <!-- <div class="h5 ">Location:</div> -->
            <!-- <p style="font-size: 14px;">99/1 Ban Mai, Amphoe Pak Kret, Nonthaburi 11120, Thailand</p> -->
            <div style="font-size: 14px;">Serial Number {{ loadData["device"]["deviceID"]  }}</div>
            <div style="font-size: 14px;">Firmware {{ loadData["device"]["firmware"]  }}</div>
            <br>
            <u class="h5">Realtime</u>
            <br>
            <div class="row ">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt-3">
                    <div style="text-decoration: none;">
                        <div class="card rounded-5 gr-card border-0" style="height: 160px;">
                            <div class="card-body">
                                <div class=" px-3 text-white">
                                    <div style="font-size: 20px;">
                                        <font-awesome-icon :icon="['fas', 'temperature-low']"
                                            style="font-size: 29px;" /> Freezer Temperature </div>
                                    <div class="text-center" style="font-size: 38px; color: white;">
                                        {{ formatTemperature(loadData["sensor"]["temp"]) }} °C
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-4  col-md-6 col-sm-6 col-12 mt-3">
                    <div to="/inside/location" style="text-decoration: none;">
                        <div class="card rounded-5   border-0 " style="height: 160px;background-color: #E6E6E6;">
                            <div class="card-body">
                                <div class=" px-3 ">
                                    <div style="font-size: 20px;font-weight: bold;">
                                        <font-awesome-icon :icon="['fas', 'door-open']" /> Door Realtime</div>
                                    <div v-if='loadData["sensor"]["door"] == false' style="font-size: 18px;">Status:
                                        Close</div>
                                    <div v-else-if='loadData["sensor"]["door"] == true' style="font-size: 18px;">Status:
                                        Open
                                    </div>
                                    <div v-else style="font-size: 18px;">{{ loadData["sensor"]["door"] }}
                                    </div>
                                    <div style="font-size: 12px;">Door opening time accumulated in daily.<span
                                            style="font-size: 16px;"></span>
                                        {{ formatDoor(loadData["door_time_day"]/60) }} min.</div>
                                    <div style="font-size: 12px;">Cumulative number of doors opened each day: <span
                                            style="font-size: 16px;"> </span> {{ loadData["door_count_day"]}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-4  col-md-6 col-sm-6 col-12 mt-3">
                    <div to="/inside/location" style="text-decoration: none;">
                        <div class="card rounded-5   border-0 " style="height: 160px;background-color: #E6E6E6;">
                            <div class="card-body">
                                <div class=" px-3 ">
                                    <div style="font-size: 20px;font-weight: bold;">
                                        <font-awesome-icon :icon="['fas', 'clock-rotate-left']" /> Door History</div>
                                    <div style="font-size: 12px;">Door opening time accumulated all <span
                                            style="font-size: 16px;"></span>{{ formatDoor(loadData["door_time"]/60) }}
                                        min.</div>
                                    <div style="font-size: 12px;">Total number of doors opened:<span
                                            style="font-size: 16px;"> </span>{{ loadData["door_count"] }}</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3"></div>
            <u class="h5 "> Setting</u>
            <div class="row ">
                <div class="col-xl-3 col-lg-4  col-md-6 col-sm-6 col-12 mt-3">
                    <div class="card rounded-5  border-0 " style="height: 290px; background-color: #E6E6E6;">
                        <div class="card-body">
                            <div class=" text-dark">

                                <div class="row">
                                    <div class="col-7">
                                        <div style="font-size: 20px;font-weight: bold;">
                                            <font-awesome-icon :icon="['fas', 'temperature-arrow-up']"
                                                style="font-size: 22px;" /> Temperature Setting</div>
                                    </div>
                                    <div class="col-5">
                                        <router-link v-if="lenTemp<2"
                                            :to="'/addsetting/?sid='+0+'&did='+$route.params.id+'&mode=Temperature&name='+items1.name"
                                            style="text-decoration: none;">
                                            <div class="text-center">
                                                <img src="../assets/pictures/setting-line-svgrepo-com.svg" height="30"
                                                    alt="">
                                            </div>
                                            <div class="text-center " style="font-size: 12px;color: black;">Setting
                                            </div>
                                        </router-link>
                                        <div v-else>
                                            <div class="text-center">
                                                <img src="../assets/pictures/setting-line-svgrepo-com.svg" height="30"
                                                    alt="">
                                            </div>
                                            <div class="text-center " style="font-size: 10px;color: blue;">Unable to set
                                                more than two values
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style="font-size: 16px;">List Setting </div>
                                <div style="height:200px;" class="overflow-auto">
                                    <div class="col-12 border rounded-4 mt-2 px-1" style="background-color: #DFDEDE;"
                                        v-for="(item, index) in items1['TempSetting']" :key="index">
                                        <div class="row">
                                            <div class="col-7" style="font-size: 13px;">
                                                <div>Condition</div>
                                                <div>{{item.name}}
                                                    <span v-if="item.condition=='min_alarm'">
                                                        &lt; </span>
                                                    <span v-else> &gt;
                                                    </span> {{ item.value}} </div>
                                                <div>To Do : {{ item.todo }}</div>
                                                <!-- <div>{{ item.id }}</div> -->
                                            </div>
                                            <div class="col-5">
                                                <router-link
                                                    :to="'/addsetting/?sid='+item.id+'&did='+$route.params.id+'&mode=Temperature&edit=edit&name='+items1.name">
                                                    <button type="button" class="btn btn-light col-6 mt-1">
                                                        <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                                        <div style="font-size: 9px;">Edit</div>
                                                    </button>
                                                </router-link>
                                                <button type="button" class="btn btn-danger col-6 mt-1"
                                                    data-bs-toggle="modal" data-bs-target="#deleteSettingModal"
                                                    @click="s_mode='Temperature',s_id=item.id">
                                                    <font-awesome-icon :icon="['fas', 'trash']" />
                                                    <div style="font-size: 9px;">Del</div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-4  col-md-6 col-sm-6 col-12 mt-3">
                    <div class="card rounded-5  border-0 " style="height: 290px; background-color: #E6E6E6;">
                        <div class="card-body">
                            <div class=" text-dark">
                                <div class="row">
                                    <div class="col-7">
                                        <div style="font-size: 20px;font-weight: bold;">
                                            <font-awesome-icon :icon="['fas', 'boxes-stacked']"
                                                style="font-size: 22px;" /> Door Setting</div>
                                    </div>
                                    <div class="col-5">
                                        <router-link v-if="lenDoor<1"
                                            :to="'/addsetting/?sid='+0+'&did='+$route.params.id+'&mode=Door&name='+items1.name"
                                            style="text-decoration: none;">
                                            <div class="text-center">
                                                <img src="../assets/pictures/setting-line-svgrepo-com.svg" height="30"
                                                    alt="">
                                            </div>
                                            <div class="text-center " style="font-size: 12px;color: black;">Setting
                                            </div>
                                        </router-link>
                                        <div v-else>
                                            <div class="text-center">
                                                <img src="../assets/pictures/setting-line-svgrepo-com.svg" height="30"
                                                    alt="">
                                            </div>
                                            <div class="text-center " style="font-size: 10px;color: blue;">Unable to set
                                                more than one values
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="font-size: 16px;">List Setting</div>
                                <div style="height:200px;" class="overflow-auto">
                                    <div class="col-12 border rounded-4 mt-2 px-1" style="background-color: #DFDEDE;"
                                        v-for="(item, index) in items1['DoorSetting']" :key="index">
                                        <div class="row">
                                            <div class="col-7" style="font-size: 13px;">
                                                <div>Condition</div>
                                                <div>{{item.name}} <span v-if="item.condition=='min_alarm'"> &lt;</span>
                                                    <span v-else> &gt;
                                                    </span> {{ item.value}} </div>
                                                <div>To Do :{{ item.todo }}</div>
                                            </div>
                                            <div class="col-5">
                                                <router-link
                                                    :to="'/addsetting/?sid='+item.id+'&did='+$route.params.id+'&mode=Door&edit=edit&name='+items1.name">
                                                    <button type="button" class="btn btn-light col-6 mt-1">
                                                        <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                                        <div style="font-size: 9px;">Edit</div>
                                                    </button>
                                                </router-link>
                                                <button type="button" class="btn btn-danger col-6 mt-1"
                                                    data-bs-toggle="modal" data-bs-target="#deleteSettingModal"
                                                    @click="s_mode='Door',s_id=item.id">
                                                    <font-awesome-icon :icon="['fas', 'trash']" />
                                                    <div style="font-size: 9px;">Del</div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="deleteSettingModal" tabindex="-1" aria-labelledby="deleteSettingModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="deleteSettingModalLabel">Delete Setting</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            Confirm Delete Setting{{ s_mode }}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                @click="delSettingOne(s_mode)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </body>

</template>

<script>
    import {
        useMQTT
    } from 'mqtt-vue-hook'
    const mqttHook = useMQTT()
    var protocol = 'wss'
    var host = 'fzm-ws.sgroup.io'
    var port = 443
    import axios from 'axios'
    import Navbar from "@/components/navbar_web.vue";
    export default {
        name: 'App',
        components: {
            Navbar
        },
        data() {
            return {
                lenTemp: 0,
                lenDoor: 0,
                s_id: null,
                s_mode: null,
                adds: null,
                loadData: {
                    "sensor": {
                        "temp": "Loading...",
                        "door": "Loading..."
                    },
                    "device": {
                        "deveiceID": "Loading...",
                        "firmware": "Loading..."
                    },
                    "record": "Loading...",
                    "timestamp": "Loading..."
                },
                items1: [],
                ttems2: [],
                randomNumber: 0,
                test: "1234",
                items: [],
                itemsOther: [{
                        setting: 1,
                        value: 0,
                    },
                    {
                        setting: 1,
                        value: 0,
                    }
                ]
            }
        },
        created() {
            this.getDataDevice();
            // .then((val) => {
            //     this.CallMQTTONE(val);
            // });
        },
        beforeUnmount() {
            clearInterval(this.intervalId);
        },
        methods: {
            formatTemperature(temp) {
                if (isNaN(temp)) {
                    return 'Loading...';
                }
                return parseFloat(temp).toFixed(2);
            },
            formatDoor(door) {
                if (isNaN(door)) {
                    return '';
                }
                return parseFloat(door).toFixed(0);
            },
            CallMQTTONE(SN) {
                mqttHook.connect(`${protocol}://${host}:${port}/ws`, {
                        username: 'frontend',
                        password: '8w95BXTq1gZH',
                        keepalive: 60,
                        connectTimeout: 4000,
                    }),
                    mqttHook.subscribe(
                        ['@msg/FZMIOT001/' + SN],
                        1, {
                            properties: {},
                        },
                        () => {
                            console.log('subscribed!')
                        }
                    )
                mqttHook.registerEvent(
                    '@msg/FZMIOT001/' + SN,
                    (_topic, message) => {
                        const messageString = message.toString('utf8');
                        this.loadData = JSON.parse(messageString);
                        console.log(JSON.parse(messageString));
                    },
                    'mqtt-client-web', // clientID
                )
            },
            async getDataDevice() {
                try {
                    var load = await axios.get('https://fzm-api.sgroup.io/api/device', {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken'),
                            'deviceid': this.$route.params.id
                        }
                    })

                    this.items1 = load.data;
                    this.lenTemp = load.data["TempSetting"].length;
                    this.lenDoor = load.data["DoorSetting"].length;
                    var a = load.data['ProjectDevices'][0]['project']['Address']
                    var b = load.data['ProjectDevices'][0]['project']['SubDistrict']
                    var c = load.data['ProjectDevices'][0]['project']['City']
                    var d = load.data['ProjectDevices'][0]['project']['Province'];
                    var e = load.data['ProjectDevices'][0]['project']['Country']
                    if (a == null) {
                        a = ""
                    }
                    if (b == null) {
                        b = ""
                    }
                    if (c == null) {
                        c = ""
                    }
                    if (d == null) {
                        d = ""
                    }
                    if (e == null) {
                        e = ""
                    }

                    var lat = load.data['ProjectDevices'][0]['project']['Latitude']
                    var lon = load.data['ProjectDevices'][0]['project']['Longitude']

                    this.adds = a + " " + b + " " + c + " " + d + " " + e + " (" + lat + "," + lon + ")";
                    console.log(load.data);
                    this.CallMQTTONE(load.data.SN);
                    // return load.data.SN;
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        // mqttHook.unSubscribe("@msg/FZMIOT001/" + this.DeviceSN)
                        // mqttHook.unRegisterEvent("@msg/FZMIOT001/" + this.DeviceSN)
                        // this.getDataDevice();
                        this.getDataDevice();
                    } else {
                        console.error(error);
                    }
                }
            },

            async delSettingOne(type) {
                try {
                    // Determine the path based on type
                    let path = (type === "Temperature") ? 'temp' : 'door';
                    // Construct the URL with query parameters
                    const url = `https://fzm-api.sgroup.io/api/setting/${path}`;
                    const params = {
                        device_id: this.$route.params.id,
                        id: this.s_id
                    };
                    // Log the URL and params for debugging
                    console.log('Request URL:', url);
                    console.log('Request Params:', params);

                    // Make the DELETE request
                    const response = await axios.delete(url, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('accessToken')
                        },
                        data: params // Axios delete request accepts 'data' to pass payload
                    });
                    console.log(path);
                    console.log('API response:', response.data);
                    this.getDataDevice();
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.log("error.response.status");
                        this.$globalFunctions.newAccToken();
                        this.delSettingOne(type);
                    } else {
                        console.error(error);
                    }
                }
            }

        },
        mounted() {
            // this.getDataDevice().then((val) => {
            //     this.CallMQTTONE(val);
            // });
        },
        unmounted() {
            mqttHook.unSubscribe("@msg/FZMIOT001/" + this.DeviceSN)
            mqttHook.unRegisterEvent("@msg/FZMIOT001/" + this.DeviceSN)
        }


    }
</script>

<style>
    .gr-card {
        background-image: linear-gradient(90deg, #5DE0E6 0%, #004AAD 100%);
        color: white;
    }

    .btn-add {
        background-image: linear-gradient(90deg, #5DE0E6 0%, #004AAD 100%);
        color: white;
    }
</style>
import {
  createWebHistory,
  createRouter
} from "vue-router";
import map from '@/components/map_main.vue'
import login from '@/components/login_main.vue'
import log from '@/components/log_main.vue'
import projectList from '@/components/listProject_main.vue'
import projectadd from '@/components/addProject_main.vue'
import deviceadd from '@/components/addDevice_main.vue'
import settingadd from '@/components/addSetting.vue'
import createusers from '@/components/createuser_page.vue'

import resetpassword from '@/components/reset_password.vue'
import forgotpassword from '@/components/forgot_password.vue'

import insideLocation from '@/components/insideLocation.vue'
import insideDevice from '@/components/inside_device.vue'

import insideChart from '@/components/inside_chart.vue'
import adduser from '@/components/supperadmin_pages/adduser_page.vue'
import addzone from '@/components/supperadmin_pages/addzone_page.vue'
import listZone from '@/components/supperadmin_pages/listzone_page.vue'
import listUser from '@/components/listUsers_page.vue'
import help_page from '@/components/help_page.vue'
import testMQTT from '@/components/testmq_page.vue'

const routes = [
  // all
  {
    path: "/mq",
    name: "mq",
    component: testMQTT,
  }, 
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: forgotpassword,
  }, 
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: resetpassword,
  }, 
  {
    path: "/help",
    name: "help",
    component: help_page,
  },  

 


  {
    path: "/supperadmin/adduser",
    name: "adduser",
    component: adduser,
  },  
  {
    path: "/supperadmin/addzone",
    name: "addzone",
    component: addzone,
  },  
  {
    path: "/supperadmin/listzone",
    name: "listzone",
    component: listZone,
  },  
  {
    path: "/listuser",
    name: "listUser",
    component: listUser,
  },  
  {
    path: "/create/user",
    name: "createuser",
    component: createusers,
  },  
  {
    path: "/map",
    name: "map",
    component: map,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/log",
    name: "log",
    component: log,
  },
  {
    path: "/",
    name: "",
    component: projectList,
  },
  {
    path: "/add",
    name: "add",
    component: projectadd,
  },
  {
    path: "/adddevice",
    name: "adddevice",
    component: deviceadd,
  },
  {
    path: "/addsetting",
    name: "addsetting",
    component: settingadd,
  },
  {
    path: '/inside/location/:id',
    name: "insidelocation",
    component: insideLocation,
  },
  {
    path: "/inside/device/:id",
    name: "indevice",
    component: insideDevice,
  },
  {
    path: "/inside/chart/:id",
    name: "inchart",
    component: insideChart,
  },
  // //supperadmin 

  // {
  //   path: "/supperadmin/log",
  //   name: "log",
  //   component: log,
  // },
  // {
  //   path: "/supperadmin/inside",
  //   name: "main_inside",
  //   component: main_inside,
  // },
  // {
  //   path: "/",
  //   name: "",
  //   component: projectList,
  // },
  // {
  //   path: "/supperadmin/add",
  //   name: "add",
  //   component: projectadd,
  // },
  // {
  //   path: "/supperadmin/adddevice",
  //   name: "adddevice",
  //   component: deviceadd,
  // },
  // {
  //   path: "/supperadmin/addsetting",
  //   name: "addsetting",
  //   component: settingadd,
  // },
  // {
  //   path: "/supperadmin/inside/location",
  //   name: "insidelocation",
  //   component: insideLocation,
  // },
  // {
  //   path: "/supperadmin/inside/device",
  //   name: "indevice",
  //   component: insideDevice,
  // },
  // {
  //   path: "/supperadmin/inside/chart",
  //   name: "inchart",
  //   component: insideChart,
  // },
  // // admin
  // {
  //   path: "/admin/log",
  //   name: "log",
  //   component: log,
  // },
  // {
  //   path: "/admin/inside",
  //   name: "main_inside",
  //   component: main_inside,
  // },
  // {
  //   path: "/admin/add",
  //   name: "add",
  //   component: projectadd,
  // },
  // {
  //   path: "/admin/adddevice",
  //   name: "adddevice",
  //   component: deviceadd,
  // },
  // {
  //   path: "/admin/addsetting",
  //   name: "addsetting",
  //   component: settingadd,
  // },
  // {
  //   path: "/admin/inside/location",
  //   name: "insidelocation",
  //   component: insideLocation,
  // },
  // {
  //   path: "/admin/inside/device",
  //   name: "indevice",
  //   component: insideDevice,
  // },
  // {
  //   path: "/admin/inside/chart",
  //   name: "inchart",
  //   component: insideChart,
  // },
  // // users
  // {
  //   path: "/users/log",
  //   name: "log",
  //   component: log,
  // },
  // {
  //   path: "/users/inside",
  //   name: "main_inside",
  //   component: main_inside,
  // },
  // {
  //   path: "/users/add",
  //   name: "add",
  //   component: projectadd,
  // },
  // {
  //   path: "/users/adddevice",
  //   name: "adddevice",
  //   component: deviceadd,
  // },
  // {
  //   path: "/users/addsetting",
  //   name: "addsetting",
  //   component: settingadd,
  // },
  // {
  //   path: "/users/inside/location",
  //   name: "insidelocation",
  //   component: insideLocation,
  // },
  // {
  //   path: "/users/inside/device",
  //   name: "indevice",
  //   component: insideDevice,
  // },
  // {
  //   path: "/users/inside/chart",
  //   name: "inchart",
  //   component: insideChart,
  // }
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;